import React, { ChangeEvent, useContext, useEffect } from 'react';

import FormPageProps from '../../types/FormPageProps.type';
import PhotoAccordion from '../../components/PhotoAccordion';
import handleImageFileChange from '../../helpers/handleImageFileChange';
import { Grid } from '@material-ui/core';
import FormPage from '../../components/FormPage';
import RadioButtons from '../../components/RadioButtons';
import handleImageDescriptionChange from '../../helpers/handleImageDescriptionChange';
import getMediaName from '../../helpers/getMediaName';
import castJSONString from '../../helpers/castJSONString';
import BtjContext from '../../context/BtjContext';
import Image from '../../types/Image.type';

function MediaAppendixForm({
  values,
  touched,
  errors,
  onBlur,
  setFieldValue,
  setFieldError,
  setActiveFields,
  textContent,
}: FormPageProps) {
  useEffect(() => {
    setActiveFields(['appendixes', 'hasAppendixes']);
  }, [setActiveFields]);
  const {
    pageHeader,
    pageInstruction,
    helperHeader,
    helperItems,
  } = textContent.appendixForm;
  const { setPromiseList } = useContext(BtjContext);

  useEffect(() => {
    if (
      values.appendixes.length < 10 &&
      values.appendixes[values.appendixes.length - 1] &&
      values.appendixes[values.appendixes.length - 1].file !== null
    ) {
      setFieldValue('appendixes', [
        ...values.appendixes,
        {
          file: null,
          description: `Bilaga/egenskap(${values.appendixes.length + 1})`,
        },
      ]);
    }
  }, [values.appendixes, setFieldValue]);

  function handleRadioChange(event: ChangeEvent<HTMLInputElement>) {
    setFieldError(event.target.name, undefined);
    setFieldValue(event.target.name, event.target.value === 'true');
  }

  function handleHasAppendixesChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (event.target.value === 'true') {
      setFieldValue('appendixes', [
        { file: null, description: 'Bilaga/egenskap' },
      ]);
    }
    if (event.target.value === 'false') {
      setFieldValue('appendixes', []);

      setPromiseList((value: Array<Image>) => [
        ...value.filter((item: Image) => item.category !== 'appendixes'),
      ]);
    }
    handleRadioChange(event);
  }

  return (
    <FormPage
      header={pageHeader}
      instruction={pageInstruction}
      helperHeader={helperHeader}
      helperItems={helperItems}>
      <Grid container direction={'column'} spacing={1}>
        <Grid item xs={12}>
          <RadioButtons
            name={'hasAppendixes'}
            text={`Har ${getMediaName(
              values.mediaType
            )} bilagor eller andra egenskaper?`}
            value={values.hasAppendixes}
            error={errors.hasAppendixes}
            touched={touched.hasAppendixes}
            onChange={(event) => handleHasAppendixesChange(event)}
            onBlur={onBlur}
            alternatives={[
              {
                label: 'Ja, det finns bilagor eller andra egenskaper',
                value: true,
              },
              { label: 'Nej', value: false },
            ]}
          />
        </Grid>
        {values.hasAppendixes && (
          <>
            <Grid container item>
              <PhotoAccordion
                images={values.appendixes}
                errors={errors.appendixes && castJSONString(errors.appendixes)}
                handleImageChange={(event, index, setPromiseList) =>
                  handleImageFileChange(
                    values,
                    'appendixes',
                    setFieldValue,
                    event,
                    index,
                    setPromiseList
                  )
                }
                handleDescriptionChange={(value: string, index: number) =>
                  handleImageDescriptionChange(
                    value,
                    index,
                    setFieldValue,
                    values
                  )
                }
                commentSection
              />
            </Grid>
          </>
        )}
      </Grid>
    </FormPage>
  );
}

export default MediaAppendixForm;

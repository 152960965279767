import React from 'react';
import { createStyles, Fab, makeStyles, Theme } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(8),
      right: theme.spacing(6),
    },
  })
);

function scrollToTop() {
  const el = document.getElementById('app');

  el &&
    el.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
}

function ScrollToTopFab() {
  const classes = useStyles();
  return (
    <Fab
      onClick={() => scrollToTop()}
      size='medium'
      color='secondary'
      aria-label='back to top'
      className={classes.fab}>
      <ArrowUpward />
    </Fab>
  );
}

export default ScrollToTopFab;

import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
      width: '100vw',
      height: '100vh',
      fontFamily: 'futura-pt',
    },
    header: {
      height: '75vh',
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    textArea: {
      fontFamily: '"freight-text-pro", "Times New Roman", serif',
      borderTop: '5px solid #E94E2C',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '25vh',
      backgroundColor: '#f8f4f4',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '@media screen and (max-width: 760px)': {
        height: '35vh',
        padding: '1.5rem',
      },
      '& h3': {
        padding: 0,
        margin: 0,
        textAlign: 'center',
      },
    },
    h1: {
      fontWeight: 'bold',
      textShadow: '0 0 6rem #ffffff',
      margin: 0,
      padding: 0,
    },
    h3: {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      padding: 0,
      color: '#E94E2C',
      textDecoration: 'none',
      cursor: 'pointer',
      position: 'absolute',
      top: '2rem',
      left: '2rem',
    },
  })
);

function NotFound() {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <header className={classes.header}>
        <Typography
          className={classes.h1}
          component={'h1'}
          variant={'h1'}
          align='center'>
          Sidan saknas
        </Typography>
        <Typography
          className={classes.h3}
          onClick={() => navigate(-1)}
          component={'h3'}
          variant={'h4'}
          align='center'>
          <ArrowBack /> Tillbaka
        </Typography>
      </header>
      <div className={classes.textArea}>
        <h3>
          Vi hittar inte sidan eller katalogen du bad om. Kontrollera att du
          angav rätt adress.
        </h3>
      </div>
    </div>
  );
}
export default NotFound;

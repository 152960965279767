import React, { useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';

function InvalidURI() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    navigate('/notfound', { replace: true });
  }, [location, navigate]);
  return null;
}

function CustomRoutes({ children }: any) {
  return (
    <Routes>
      {children}
      <Route path='*' element={<InvalidURI />} />
    </Routes>
  );
}

export default CustomRoutes;

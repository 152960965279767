import React, { useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useNavigate, useLocation, useNavigationType } from 'react-router-dom';
import { FormikErrors } from 'formik';
import FormValues from '../types/FormValues.type';
import { ArrowBack } from '@material-ui/icons';
import CustomSnackBar from './CustomSnackBar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 0,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  })
);

type Props = {
  resetErrors: () => void;
  routes: string[];
  errors: FormikErrors<FormValues>;
  activeFields: string[];
  validateFields: (fieldNames: string[]) => boolean;
  submit: () => void;
  isOrderFlow?: boolean;
  isAutoSearch?: boolean;
  setIsAutoSearch?: Function;
};

export default function FormNavigation({
  activeFields,
  errors,
  resetErrors,
  routes,
  validateFields,
  submit,
  isOrderFlow = true,
  isAutoSearch = false,
  setIsAutoSearch,
}: Props) {
  const navType = useNavigationType();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [routeIndex, setRouteIndex] = useState<number>(0);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [activeRoutes, setActiveRoutes] = useState<string[]>([]);

  useEffect(() => {
    //inserts first route
    //@ts-ignore
    const currentRoute = location.pathname.match(/[^/]*$/)[0];
    setActiveRoutes([currentRoute]);

    if (navType === 'POP' && routes.find((r) => r === currentRoute)) {
      navigate('/order');
    }
  }, []);

  useEffect(() => {
    const currentRoute = location.pathname.match(/[^/]*$/);

    if (!!currentRoute) {
      const currentIndex = routes.findIndex((r) => r === currentRoute[0]);

      if (currentIndex !== -1) {
        setRouteIndex(currentIndex);
      }
    }
  }, [location, routes]);

  useEffect(() => {
    if (isAutoSearch) {
      onSubmitClick();
      setIsAutoSearch && setIsAutoSearch(false);
    }
  }, [isAutoSearch, setIsAutoSearch]);

  async function onSubmitClick() {
    const hasErrors = isOrderFlow
      ? validateFields(['numberOfLabels', 'notes'])
      : validateFields(['isbn']);
    if (hasErrors) {
      setSnackBarOpen(true);
    } else {
      submit();
    }
  }

  function onNextClick() {
    const hasErrors = validateFields(activeFields);
    if (hasErrors) {
      setSnackBarOpen(true);
    } else {
      resetErrors();
      if (routeIndex < routes.length - 1) {
        if (
          activeRoutes.findIndex((r) => r === routes[routeIndex + 1]) === -1
        ) {
          setActiveRoutes((ar) => [...ar, routes[routeIndex + 1]]);
          navigate(routes[routeIndex + 1]);
        } else {
          navigate(1);
        }

        window.scrollTo(0, 0);
      }
    }
  }

  function onPrevious() {
    if (!isOrderFlow) {
      navigate('/', { replace: true });
    } else if (routeIndex > 0) {
      resetErrors();
      navigate(-1);
      window.scrollTo(0, 0);
    }
  }

  return (
    <>
      <Grid
        container
        className={classes.root}
        direction='row'
        justify='space-evenly'>
        <Grid item xs={5}>
          <Button
            style={{ background: isOrderFlow ? 'unset' : '#fff' }}
            fullWidth={true}
            onClick={onPrevious}
            disabled={isOrderFlow && routeIndex === 0}
            startIcon={!isOrderFlow ? <ArrowBack /> : null}>
            {isOrderFlow ? 'Föregående' : 'Tillbaka'}
          </Button>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5}>
          {!(routeIndex === routes.length - 1) ? (
            <Button
              color={'primary'}
              variant={'contained'}
              fullWidth={true}
              onClick={() => onNextClick()}>
              Nästa
            </Button>
          ) : (
            <Button
              fullWidth={true}
              onClick={() => onSubmitClick()}
              variant={'contained'}
              color={'primary'}
              name={'title'}
              type='submit'>
              {isOrderFlow ? 'Skicka' : 'Sök'}
            </Button>
          )}
        </Grid>
      </Grid>
      {snackBarOpen && (
        <CustomSnackBar
          type='error'
          message={errors}
          onClose={() => setSnackBarOpen(false)}
        />
      )}
    </>
  );
}

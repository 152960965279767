import React from 'react';
import sanitize from 'sanitize-html';

const defaultConfig = {
  allowedTags: [
    'address',
    'article',
    'aside',
    'footer',
    'header',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hgroup',
    'main',
    'nav',
    'section',
    'blockquote',
    'dd',
    'div',
    'dl',
    'dt',
    'figcaption',
    'figure',
    'hr',
    'li',
    'main',
    'ol',
    'p',
    'pre',
    'ul',
    'a',
    'abbr',
    'b',
    'bdi',
    'bdo',
    'br',
    'cite',
    'code',
    'data',
    'dfn',
    'em',
    'i',
    'kbd',
    'mark',
    'q',
    'rb',
    'rp',
    'rt',
    'rtc',
    'ruby',
    's',
    'samp',
    'small',
    'span',
    'strong',
    'sub',
    'sup',
    'time',
    'u',
    'var',
    'wbr',
    'caption',
    'col',
    'colgroup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',
    'img',
    'iframe',
    'video',
  ],
  allowedAttributes: {
    a: ['href', 'name', 'target'],
    img: [
      'src',
      'srcset',
      'alt',
      'title',
      'width',
      'height',
      'loading',
      'style',
      'align',
      'sizes',
      'valign',
    ],
    iframe: ['src', 'frameborder', 'allow', 'allowfullscreen'],
    video: ['src'],
  },
  selfClosing: [
    'img',
    'br',
    'hr',
    'area',
    'base',
    'basefont',
    'input',
    'link',
    'meta',
  ],
  allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
  allowedSchemesByTag: {},
  allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
  allowProtocolRelative: true,
  enforceHtmlBoundary: false,
};

type SanitizedProps = {
  html: string;
  className?: string;
  extendedConfig?: Object;
};

function Sanitized({
  html = '',
  className = '',
  extendedConfig,
}: SanitizedProps) {
  let sanitized;

  const config = !!extendedConfig
    ? {
        ...defaultConfig,
        allowedAttributes: {
          ...defaultConfig.allowedAttributes,
          ...extendedConfig,
        },
      }
    : defaultConfig;

  try {
    sanitized = sanitize(decodeURI(html), config);
  } catch (error) {
    sanitized = sanitize(html, config);
  }

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: sanitized }}
    />
  );
}

export default Sanitized;

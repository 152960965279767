import OrderHistoryValues from '../../types/OrderHistoryValues.type';

async function getOngoingOrdersFromApi(): Promise<
  OrderHistoryValues[] | undefined
> {
  const res = await fetch('/api/v1/titlepage/catalogingorder/ongoing', {
    method: 'GET',
  });

  if (res.status === 200) {
    return res.json();
  }
}

export default getOngoingOrdersFromApi;

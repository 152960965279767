import LibraryInfoValues from '../../types/LibraryInfoValues.type';

async function getQuotaExcessFromApi(): Promise<
  LibraryInfoValues[] | undefined
> {
  const res = await fetch('api/v1/titlepage/catalogingorder/excess', {
    method: 'GET',
  });

  if (res.status === 200) {
    return res.json();
  }
}

export default getQuotaExcessFromApi;

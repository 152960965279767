import UserProfile from '../types/UserProfile.type';

async function getWhoAmIFromApi(): Promise<UserProfile | null> {
  const res = await fetch('/api/v1/identity/whoami', {
    method: 'GET',
  });
  if (res.status === 200) {
    return res.json();
  }
  return null;
}

export default getWhoAmIFromApi;

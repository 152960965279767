import OrderHistoryValues from '../../types/OrderHistoryValues.type';

async function getCompletedOrdersFromApi(): Promise<
  OrderHistoryValues[] | undefined
> {
  const res = await fetch('/api/v1/titlepage/catalogingorder/completed', {
    method: 'GET',
  });

  if (res.status === 200) {
    return res.json();
  }
}

export default getCompletedOrdersFromApi;

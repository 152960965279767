import React, { useState } from 'react';
import {
  Button,
  createStyles,
  Grid,
  Link as MuiLink,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAuth } from 'oidc-react';
import { Link as RouterLink } from 'react-router-dom';
import InfoPopup from '../components/InfoPopup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainButton: {
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'initial',
      fontSize: '1rem',
      borderRadius: '25px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    noAccessButton: {
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'initial',
      fontSize: '1rem',
      borderRadius: '25px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      filter: 'brightness(1.5)',
    },
    logoutButton: {
      textTransform: 'initial',
      paddingTop: theme.spacing(8),
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '1rem',
    },
    header: {
      fontSize: '2rem',
      fontWeight: theme.typography.fontWeightBold,
    },
    text: {
      fontSize: '1.2rem',
      fontWeight: theme.typography.fontWeightMedium,
    },
  })
);

function Login() {
  const [showKatalogiseringInfo, setShowKatalogiseringInfo] = useState<boolean>(
    false
  );
  const [
    showRecensionsbankenInfo,
    setShowRecensionsbankenInfo,
  ] = useState<boolean>(false);
  const classes = useStyles();
  const auth = useAuth();

  return (
    <>
      {!!showKatalogiseringInfo && (
        <InfoPopup
          title='Du måste vara inloggad för att använda den här funktionen'
          information='Information om tjänsten finner du här'
          link='https://www.btj.se/bestall-katalogisering_pre'
          handleConfirm={() => {
            setShowKatalogiseringInfo(false);
          }}
        />
      )}
      {!!showRecensionsbankenInfo && (
        <InfoPopup
          title='Du måste vara inloggad för att använda den här funktionen'
          information='Information om tjänsten finner du här'
          link='https://www.btj.se/recensionsbanken_pre'
          handleConfirm={() => {
            setShowRecensionsbankenInfo(false);
          }}
        />
      )}
      <Grid item>
        <Button
          className={classes.noAccessButton}
          variant='contained'
          fullWidth
          color='primary'
          onClick={() => {
            setShowKatalogiseringInfo(true);
          }}>
          Beställ katalogisering
        </Button>
      </Grid>
      <Grid item>
        <Button
          style={{ marginBottom: '2rem' }}
          className={classes.noAccessButton}
          variant='contained'
          fullWidth
          color='primary'
          onClick={() => {
            setShowRecensionsbankenInfo(true);
          }}>
          Recensionsbanken
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={classes.mainButton}
          variant='contained'
          fullWidth
          color='secondary'
          onClick={() =>
            auth.signIn({
              extraQueryParams: {
                scheme: 'Btj',
                startidpentityid: 'Btj',
              },
            })
          }>
          Till inloggning
        </Button>
      </Grid>
      <Grid item>
        <Typography
          className={classes.text}
          component={'p'}
          variant={'h3'}
          align={'center'}
          paragraph>
          Saknar du inloggningsuppgifter? <br />
          <MuiLink href='https://www.btj.se/skapa-anvandare-i-btj-appen'>
            Registrera dig här
          </MuiLink>
        </Typography>
      </Grid>
    </>
  );
}

export default Login;

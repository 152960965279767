import SubmitValues from '../types/SubmitValues.type';

const ENDPOINT = '/api/v1/titlepage/CatalogingOrder';

export default async function postForm(formData: SubmitValues) {
  let response;
  formData =
    formData.isbn !== ''
      ? { ...formData, isbn: formData.isbn.replace(/-/g, '') }
      : formData;
  try {
    response = await fetch(ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    response = err;
  }
  return response;
}

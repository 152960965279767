import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { HelperItem } from './HelpButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    helperImage: {
      maxWidth: '80%',
      objectFit: 'contain',
      borderStyle: 'solid',
      borderWidth: '0.5px',
      borderColor: theme.palette.primary.dark,
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })
);

type Props = {
  helperItems: HelperItem[];
  selectedTab: string | boolean;
};

function HelperAccordion({ helperItems, selectedTab }: Props): JSX.Element {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean | string>(false);

  useEffect(() => {
    setExpanded(selectedTab);
  }, [selectedTab]);

  const handleAccordionChange = (target: string) => {
    if (expanded === target) {
      setExpanded(false);
    } else setExpanded(target);
  };
  const drawers = helperItems.map(
    (item: HelperItem, index): JSX.Element => {
      const { header, paragraph, imageURL, imageAlt } = item;
      return (
        <Accordion
          key={index}
          expanded={expanded === header}
          onChange={() => handleAccordionChange(header)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color={'primary'} variant={'h3'} component={'h2'}>
              {header}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction={'column'}>
              <Grid item>
                {paragraph.split('\n').map((p, index) => (
                  <Typography
                    key={index}
                    paragraph
                    variant={'body2'}
                    component={'p'}>
                    {p}
                  </Typography>
                ))}
              </Grid>
              <Grid item>
                {imageURL && (
                  <img
                    className={classes.helperImage}
                    src={imageURL}
                    alt={imageAlt}
                  />
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    }
  );

  return <>{drawers}</>;
}

export default HelperAccordion;

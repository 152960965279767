import React, { useContext, useEffect } from 'react';
import {
  Button,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography,
  Dialog,
  Paper,
} from '@material-ui/core';
import logo from '../assets/logo.svg';
import { useAuth } from 'oidc-react';
import BtjContext from '../context/BtjContext';
import { useNavigate } from 'react-router-dom';
import bg from '../assets/images/homebg.jpg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundImage: `url('${bg}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    paper: {
      height: '100%',
      width: '100%',
      padding: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
      border: 'none !important',
      '@media(min-width:420px)': {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(12),
      },
    },
    rootLogo: {
      width: '100%',
    },
    logo: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '50%',
    },
  })
);

function NoSubscription() {
  const classes = useStyles();
  const navigate = useNavigate();
  const auth = useAuth();
  const { userProfile } = useContext(BtjContext);

  useEffect(() => {
    if (!!userProfile) {
      navigate('/');
    }
  }, [userProfile, navigate]);

  return (
    <Dialog
      aria-labelledby='No subscription'
      scroll='body'
      maxWidth={'xs'}
      className={classes.root}
      open={true}>
      <Paper className={classes.paper}>
        <Grid
          container
          direction={'column'}
          justify='center'
          alignItems='center'>
          <Grid className={classes.rootLogo} item>
            <img className={classes.logo} src={logo} alt='BTJ logo' />
          </Grid>
          <Grid item>
            <Typography
              component={'h1'}
              variant={'h1'}
              align={'center'}
              paragraph>
              Ditt användarnamn finns <br /> inte registrerat
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component={'p'}
              variant={'body2'}
              align={'center'}
              paragraph>
              Vad kul att du vill använda BTJ-appen! Tyvärr finns ditt
              användarnamn inte registrerat för denna tjänst. Prova gärna att
              logga in med ett annat användarnamn.
            </Typography>
            <Grid item>
              <Typography
                component={'p'}
                variant={'body2'}
                align={'center'}
                paragraph>
                Kvarstår problemet, kontakta <br />
                <Link href='mailto:btj-appen@btj.se'>btj-appen@btj.se</Link>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                size={'large'}
                color={'secondary'}
                variant={'contained'}
                fullWidth
                onClick={() => auth.signOutRedirect()}>
                Tillbaka till förstasidan
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
}

export default NoSubscription;

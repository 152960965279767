import FormValues from '../types/FormValues.type';
import SubmitValues, { ImageSubmitModel } from '../types/SubmitValues.type';
import { v4 as uuid } from 'uuid';

export default function adaptFormValuesToPostValues(
  formValues: FormValues,
  images: Array<ImageSubmitModel> = []
): SubmitValues {
  return {
    id: uuid(),
    appendixes: !!formValues.hasAppendixes,
    illustrations: !!formValues.hasIllustrations,
    images: images,
    isbn: formValues.isbn || '',
    languages: formValues.languages
      ? formValues.languages.map(({ code }) => code)
      : [],
    mediaType: formValues.mediaType,
    notes: formValues.notes || '',
    numberOfLabels: formValues.numberOfLabels || 0,
    numberOfPages: formValues.numberOfPages || '',
    paginationUnknown: formValues.paginationUnknown || false,
    paginationMissing: formValues.paginationMissing || false,
    parallelText: formValues.parallelText || false,
    spiralBinding: formValues.spiralBinding || false,
    tableOfContents: formValues.tableOfContents || false,
  };
}

import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  createStyles,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import { useFormik } from 'formik';

// Data & Types
import textContent from '../../assets/textContent.json';
import FormValues from '../../types/FormValues.type';

// Components
import BookResultsPage from './ResultsPage/BookResultsPage';
import FormHeader from '../../components/FormHeader';
import FormNavigation from '../../components/FormNavigation';
import IsbnForm from './IsbnForm/IsbnForm';
import LanguageForm from './LanguageForm/LanguageForm';
import AppendixForm from './AppendixForm/AppendixForm';
import ContentForm from './ContentForm/ContentForm';
import PhotoForm from './PhotoForm/PhotoForm';
import { Route, useNavigate } from 'react-router-dom';
import { initialBookValues } from './BookOrderInitialvalues';
import BookOrderValidationSchema from './BookOrderValidation.schema';
import validateFields from '../../helpers/validateFields';
import adaptFormValuesToPostValues from '../../helpers/formToPost.adapter';
import postForm from '../../services/Form.service';
import LoadingModal from '../../components/LoadingModal';
import CustomRoutes from '../../components/CustomRoutes';
import BtjContext from '../../context/BtjContext';
import mapImageUploadingResults from '../../helpers/mapImageUploadingResults';

import {
  ORDER_SUBMISSION_FAILED,
  ORDER_SUBMITTED_SUCCESSFULLY,
} from '../../Constants';
import CustomSnackBar from '../../components/CustomSnackBar';

const ROUTES = [
  'isbn',
  'language',
  'content',
  'photos',
  'appendixes',
  'results',
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      display: 'none',
    },
    formPaper: {
      border: 'none',
      minHeight: '100vh',
      paddingTop: theme.spacing(12),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      '& form': {
        flex: 1,
      },
    },
  })
);

function BookForm() {
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    resetForm,
    setErrors,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormik<FormValues>({
    initialValues: initialBookValues,
    validationSchema: BookOrderValidationSchema,
    onSubmit: submitForm,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const [activeFields, setActiveFields] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackbarStatus, setSnackbarStatus] = useState<{
    severity: 'success' | 'info' | 'warning' | 'error';
    text: any;
    isShowing: boolean;
  }>({ severity: 'info', text: '', isShowing: false });

  const resetErrors = () => setErrors({});
  const { promiseList, setPromiseList } = useContext(BtjContext);

  useEffect(() => {
    return () => {
      setPromiseList([]);
    };
  }, []);

  async function submitForm(values: FormValues) {
    setIsLoading(true);

    Promise.allSettled(promiseList.map((item) => item.promise))
      .then((result) => {
        const { images, errors } = mapImageUploadingResults(
          promiseList,
          result,
          values
        );

        if (!(errors.length > 0)) {
          postForm(adaptFormValuesToPostValues(values, images)).then(
            (result) => {
              setTimeout(() => {
                setIsLoading(false);
                if (result.status === 200) {
                  setSnackbarStatus({
                    severity: 'success',
                    text: ORDER_SUBMITTED_SUCCESSFULLY,
                    isShowing: true,
                  });
                } else {
                  setSnackbarStatus({
                    severity: 'error',
                    text: ORDER_SUBMISSION_FAILED,
                    isShowing: true,
                  });
                }
              }, 1000);
            }
          );
        } else {
          throw errors;
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setSnackbarStatus({
          severity: 'error',
          text: Array.isArray(error) ? error : ORDER_SUBMISSION_FAILED,
          isShowing: true,
        });
      });
  }

  function resetValues(initialValues: FormValues) {
    const initialImageDescriptions = initialValues.images.map(
      (value) => value.description
    );
    const nextImages = [...values.images];
    nextImages.forEach((image, index) => {
      image.file = null;
      delete image.imageFileId;
      image.description = initialImageDescriptions[index];
    });
    setFieldValue('images', nextImages);
    setFieldValue('appendixes', []);
    setFieldValue('illustrations', []);
    resetForm({
      values: {
        ...initialValues,
        images: nextImages,
        appendixes: [],
        illustrations: [],
      },
    });
  }

  function blurAndValidate(event: React.FocusEvent<HTMLInputElement>) {
    handleBlur(event);
    validateFields(
      BookOrderValidationSchema,
      values,
      [event.target.name],
      setFieldTouched,
      setFieldError,
      true
    );
  }

  function changeAndValidate(event: React.ChangeEvent<HTMLInputElement>) {
    handleChange(event);
    //@ts-ignore
    touched[event.target.name] &&
      validateFields(
        BookOrderValidationSchema,
        values,
        [event.target.name],
        setFieldTouched,
        setFieldError,
        true
      );
  }

  function validate(targetPaths: Array<string>) {
    validateFields(
      BookOrderValidationSchema,
      values,
      targetPaths,
      setFieldTouched,
      setFieldError,
      true
    );
  }

  function handleSnackbarClose() {
    if (snackbarStatus.severity === 'success') {
      resetValues(initialBookValues);
      navigate('/');
    }
    setSnackbarStatus({ ...snackbarStatus, isShowing: false });
  }
  const formPageProps = {
    errors,
    onBlur: blurAndValidate,
    onChange: changeAndValidate,
    setActiveFields,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    touched,
    validate,
    values,
    textContent: textContent.bookOrder,
  };

  return (
    <Container maxWidth={'sm'}>
      <LoadingModal isLoading={isLoading} />
      {snackbarStatus.isShowing && (
        <CustomSnackBar
          type={snackbarStatus.severity}
          message={snackbarStatus.text}
          onClose={handleSnackbarClose}
        />
      )}
      <Paper className={classes.formPaper}>
        <FormHeader
          resetForm={() => resetValues(initialBookValues)}
          textContent={'NY BESTÄLLNING: BOK'}
        />
        <CustomRoutes>
          <Route path='isbn' element={<IsbnForm {...formPageProps} />} />
          <Route
            path='language'
            element={<LanguageForm {...formPageProps} />}
          />
          <Route path='content' element={<ContentForm {...formPageProps} />} />
          <Route path='photos' element={<PhotoForm {...formPageProps} />} />
          <Route
            path='appendixes'
            element={<AppendixForm {...formPageProps} />}
          />
          <Route
            path='results'
            element={<BookResultsPage {...formPageProps} />}
          />
        </CustomRoutes>
        <FormNavigation
          resetErrors={resetErrors}
          activeFields={activeFields}
          errors={errors}
          routes={ROUTES}
          submit={() => submitForm(values)}
          validateFields={(targetPaths) =>
            validateFields(
              BookOrderValidationSchema,
              values,
              targetPaths,
              setFieldTouched,
              setFieldError,
              true
            )
          }
        />
      </Paper>
    </Container>
  );
}

export default BookForm;

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PhotoCamera } from '@material-ui/icons';
import { ImageFileViewModel } from '../types/FormValues.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      borderRadius: 0,
    },
    accordionPaper: {
      border: 'none',
      padding: theme.spacing(4),
    },
    previewImage: {
      maxWidth: '100%',
      maxHeight: '50vh',
      objectFit: 'contain',
      margin: '0 auto',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      borderWidth: '0.2px',
    },
    removeButton: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.light,
      },
    },
  })
);

type Props = {
  expanded: boolean;
  error?: boolean;
  handleExpansion: (event: ChangeEvent<{}>, expanded: boolean) => void;
  image: ImageFileViewModel;
  handleImageChange: React.ChangeEventHandler<HTMLInputElement>;
  handleDescriptionChange?: (value: string) => void;
  commentSection: boolean;
};

function PhotoDrawer({
  commentSection,
  error,
  expanded,
  handleImageChange,
  handleDescriptionChange,
  handleExpansion,
  image,
}: Props) {
  const { file, description } = image;
  const classes = useStyles();
  const previewRef = useRef<HTMLImageElement | null>(null);
  const [pictureTaken, setPictureTaken] = useState<boolean>(false);
  const [edited, setEdited] = useState<boolean>(false);

  function handleSelect() {
    if (!edited && handleDescriptionChange) {
      handleDescriptionChange('');
      setEdited(true);
    }
  }
  useEffect(() => {
    if (file) {
      previewRef.current?.setAttribute('src', URL.createObjectURL(file));
      setPictureTaken(true);
    }
  }, [file]);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpansion}
      className={classes.accordion}>
      <AccordionSummary
        expandIcon={
          error && !file ? <ErrorIcon color={'error'} /> : <ExpandMoreIcon />
        }>
        <Typography variant={'subtitle1'} component={'h3'}>
          {image.description.length > 25
            ? image.description.substring(0, 22).concat('. . .')
            : image.description}
        </Typography>
      </AccordionSummary>
      <Paper className={classes.accordionPaper}>
        <Grid container direction={'column'} spacing={2}>
          <input
            id={`${description}-camera`}
            accept='image/*'
            style={{ display: 'none' }}
            type='file'
            onClick={(event) => {
              const element = event.target as HTMLInputElement;
              element.value = '';
            }}
            onChange={(event) => {
              if (
                event.target.files?.length &&
                event.target.files?.length > 0
              ) {
                handleImageChange(event);
              }
            }}
          />
          <Grid item>
            <img
              style={{ display: !pictureTaken ? 'none' : 'block' }}
              ref={previewRef}
              className={classes.previewImage}
              alt={`förhandsvisning av bilden av ${description}`}
              src={''}
            />
          </Grid>
          {commentSection && handleDescriptionChange && (
            <Grid item>
              <TextField
                inputProps={{ maxLength: '200' }}
                value={description}
                onSelect={() => handleSelect()}
                onChange={(event) =>
                  handleDescriptionChange(event.target.value)
                }
                label={'Beskrivning'}
                placeholder={'T.ex. "Utvikbar sida"'}
                multiline={true}
                rows={1}
                rowsMax={5}
                fullWidth
              />
            </Grid>
          )}
          <Grid item>
            <label htmlFor={`${description}-camera`}>
              <Button
                startIcon={<PhotoCamera />}
                fullWidth
                variant='contained'
                color='primary'
                component='span'>
                <Typography variant={'subtitle2'} component={'span'}>
                  {!pictureTaken
                    ? `Fotografera ${commentSection ? '' : image.description}`
                    : 'Ta om bild'}
                </Typography>
              </Button>
            </label>
          </Grid>
        </Grid>
      </Paper>
    </Accordion>
  );
}

export default PhotoDrawer;

import { useAuth } from 'oidc-react';
import React, { useEffect, useState } from 'react';
import { BookReview } from '../types/BookReview';
import BtjContext from './BtjContext';
import getWhoAmIFromApi from '../services/WhoAmI.service';
import UserProfile from '../types/UserProfile.type';
import Image from '../types/Image.type';

const BtjState = (props: any) => {
  const auth = useAuth();
  const [review, setReview] = useState<BookReview | null>(null);
  const [userProfile, setUserProfile] = useState<
    UserProfile | null | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean | null>(null);
  const [modalTab, setModalTab] = useState<string | boolean>(false);
  const [promiseList, setPromiseList] = useState<Array<Image>>([]);

  useEffect(() => {
    const getUserProfile = async () => {
      const _user = await getWhoAmIFromApi();

      setUserProfile(_user);
      setLoading(false);
    };

    if (auth.userData && !userProfile) {
      getUserProfile();
    }
  }, [userProfile, auth.userData]);

  useEffect(() => {
    auth.userManager.getUser().then((user) => {
      if (!!user) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    });
  }, [auth.userManager]);

  const store = {
    userProfile: userProfile,
    review: review,
    setReview: setReview,
    loading: loading,
    modalTab: modalTab,
    setModalTab: setModalTab,
    promiseList: promiseList,
    setPromiseList: setPromiseList,
  };

  return (
    <BtjContext.Provider value={store}>{props.children}</BtjContext.Provider>
  );
};

export default BtjState;

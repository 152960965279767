import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import HelperAccordion from './HelperAccordion';
import HelpIcon from '@material-ui/icons/Help';
import BtjContext from '../context/BtjContext';

export type HelperItem = {
  header: string;
  paragraph: string;
  imageURL?: string;
  imageAlt?: string;
};

type Props = {
  helperItems: HelperItem[];
  header?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      height: '80vh',
      minWidth: '90%',
    },
    header: {
      color: theme.palette.primary.main,
    },
  })
);

function HelpButton({ helperItems, header }: Props) {
  const { modalTab, setModalTab } = useContext(BtjContext);
  const classes = useStyles();
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleHelpClick = () => {
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
    setModalTab(false);
  };

  useEffect(() => {
    if (!!modalTab) {
      setShowModal(true);
    }
  }, [modalTab]);

  return (
    <div>
      <IconButton color={'primary'} onClick={handleHelpClick}>
        <HelpIcon />
      </IconButton>
      <Dialog
        open={showModal}
        onClose={handleModalClose}
        aria-labelledby='Hjälptext'
        aria-describedby='Svar på vanliga frågor om den här delen av formuläret'>
        <Paper className={classes.paper}>
          <Grid
            container
            direction='column'
            spacing={4}
            justify='space-between'
            alignItems='center'>
            <Grid
              container
              direction='column'
              item
              spacing={1}
              justify='space-between'
              alignItems='center'>
              <Grid item>
                <Icon>
                  <HelpIcon />
                </Icon>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.header}
                  variant={'h2'}
                  component={'h1'}
                  align={'center'}
                  gutterBottom>
                  {header}
                </Typography>
              </Grid>
            </Grid>
            <Grid item></Grid>
            <Grid item>
              <HelperAccordion
                helperItems={helperItems}
                selectedTab={modalTab}
              />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                onClick={handleModalClose}
                variant={'contained'}
                color={'primary'}>
                Stäng
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </div>
  );
}

export default HelpButton;

//@ts-nocheck
import React from 'react';
import { beep } from '../helpers/scanHelpers';
import GAEvent from '../helpers/GAEvent';

const CANVAS_SIZE = {
  WIDTH: 320,
  HEIGHT: 430,
};

const sw = CANVAS_SIZE.WIDTH;
const sh = CANVAS_SIZE.HEIGHT;
const dw = sw;
const dh = sh;
const dx = 0;
const dy = 0;
let sx = 0;
let sy = 0;

const crossHairSvg =
  'M77.125 148.02567c0-3.5774 2.73862-6.27567 6.37076-6.27567H119V117H84.0192C66.50812 117 52 130.77595 52 148.02567V183h25.125v-34.97433zM237.37338 117H202v24.75h35.18494c3.63161 0 6.69006 2.69775 6.69006 6.27567V183H269v-34.97433C269 130.77595 254.88446 117 237.37338 117zM243.875 285.4587c0 3.5774-2.73863 6.27567-6.37076 6.27567H202V317h35.50424C255.01532 317 269 302.70842 269 285.4587V251h-25.125v34.4587zM83.49576 291.73438c-3.63213 0-6.37076-2.69776-6.37076-6.27568V251H52v34.4587C52 302.70842 66.50812 317 84.0192 317H119v-25.26563H83.49576z';
const crossHairWidth = 217,
  crossHairHeight = 200,
  x0 = 53,
  y0 = 117;

//@ts-ignore
interface IBarCodeReader {
  userProfile: PropTypes.object;
  handleBarCode: PropTypes.func;
}

class BarCodeReader<IBarCodeReader> extends React.Component<IBarCodeReader> {
  constructor(props) {
    super(props);
    this.video = document.createElement('video');
    this.video.onplaying = () => {
      sx = (this.video.videoWidth - CANVAS_SIZE.WIDTH) / 2;
      sy = (this.video.videoHeight - CANVAS_SIZE.HEIGHT) / 2;
    };
    this.state = {
      scanning: false,
      resultOpen: false,
    };

    this.scanRate = 250;

    this.qrworker = null;
    this.oldTime = 0;
  }

  registerGAEvent = (success:string) => {
    const userProfile = this.props.userProfile;
    GAEvent('Enter isbn', {
      customerName: userProfile ? userProfile.customerName : 'anonymous',
      appenService: 'Recensionsbanken',
      actionDetail: 'barcode',
      success,
    });
  }

  initWorker = () => {
    this.qrworker = new Worker('/wasmBarcodeWorker.js');

    this.qrworker.onmessage = (ev) => {
      if (ev.data != null) {
        this.qrworker.terminate();
        const result = ev.data;
        this.stopScan();
        let res = result.data;
        this.setState({ barcode: res, resultOpen: true });
        beep();
      }
    };
  };

  startScan = () => {
    this.initWorker();

    this.setState({
      scanning: true,
      barcode: '',
      resultOpen: false,
    });
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: { facingMode: 'environment' } })
      .then((stream) => {
        this.video.srcObject = stream;
        this.video.setAttribute('playsinline', 'true');
        this.video.play();
        requestAnimationFrame(this.tick);
      })
      .catch((err) => {
        this.registerGAEvent('failure')
        this.stopScan();
        alert(err);
      });
  };

  stopScan = () => {
    this.setState({
      scanning: false,
      boxShadow:
        '0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19)',
    });
    this.video.pause();
    if (this.video.srcObject) {
      this.video.srcObject.getVideoTracks().forEach((track) => track.stop());
      this.video.srcObject = null;
    }
  };

  tick = (time) => {
    if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
      this.canvas.drawImage(this.video, sx, sy, sw, sh, dx, dy, dw, dh);
      this.drawCrosshair();
      if (this.state.scanning) requestAnimationFrame(this.tick);
      this.recogniseQRcode(time);
    } else if (this.state.scanning) requestAnimationFrame(this.tick);
  };

  drawCrosshair = () => {
    this.canvas.fillStyle = 'rgba(255,255,255,0.4)';
    const shape = new Path2D(crossHairSvg);
    this.canvas.fill(shape);
  };

  recogniseQRcode = (time) => {
    if (time - this.oldTime > this.scanRate) {
      this.oldTime = time;
      let imageData;
      imageData = this.canvas.getImageData(
        x0,
        y0,
        crossHairWidth,
        crossHairHeight
      );

      this.qrworker.postMessage({
        width: imageData.width,
        height: imageData.height,
      });
      this.qrworker.postMessage(imageData, [imageData.data.buffer]);
    }
  };

  componentDidMount() {
    this.canvasElement = document.getElementById('canvas');
    this.canvas = this.canvasElement.getContext('2d');
    this.canvasElement.width = CANVAS_SIZE.WIDTH;
    this.canvasElement.height = CANVAS_SIZE.HEIGHT;

    this.startScan();
  }

  render() {
    return (
      <div
        style={{
          margin: '15px auto',
          textAlign: 'center',
        }}>
        <canvas
          id='canvas'
          style={{
            boxShadow: 'inset 0 0 0 1px #e6e6e6',
            borderRadius: '6px',
          }}
        />
      </div>
    );
  }

  componentWillUnmount() {
    this.stopScan();
  }

  componentDidUpdate() {
    if (this.state.resultOpen) {
      this.registerGAEvent('success')
      this.props.handleBarCode(this.state.barcode);
    }
  }
}
export default BarCodeReader;

import React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Slide,
  Theme,
  Typography,
  Link,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0',
      border: 'none',
      width: '100%',
      minHeight: '100vh',
      height: '100vh',
    },
    wrapper: {
      backgroundColor: '#fff',
      minHeight: '100%',
      display: 'grid',
      gridTemplateRows: '3rem auto',
      gap: '2rem',
      padding: '5rem 2.5rem 2rem 2.5rem',
    },
    header: {
      '& h1': {
        textAlign: 'left',
        fontWeight: 'bold',
      },
    },
    content: {
      '& p': {
        textAlign: 'left',
        paddingTop: '.25rem',
        '& a': {
          color: '#00635e',
        },
      },
    },
    back: {
      position: 'absolute',
      top: 0,
      left: '2rem',
      '& a': {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        fontFamily: 'futura-pt',
        color: '#E94E2C',
        cursor: 'pointer',
        margin: '0',
        padding: '0',
        marginTop: '2rem',
        textDecoration: 'none',
      },
    },
  })
);

function CookiePolicy() {
  const classes = useStyles();

  return (
    <Slide direction={'left'} in={true}>
      <Paper className={classes.root} variant='outlined'>
        <Grid className={classes.back} item>
          <RouteLink to='/'>
            <ArrowBack />
            <Typography component={'h3'} variant={'h4'}>
              Tillbaka
            </Typography>
          </RouteLink>
        </Grid>
        <Grid className={classes.wrapper}>
          <Grid className={classes.header} item>
            <Typography component={'h1'} variant={'h1'} align='center'>
              Kakor i BTJ-appen
            </Typography>
          </Grid>
          <Grid className={classes.content} item>
            <Typography
              component={'p'}
              variant={'body2'}
              align='center'
              paragraph>
              En kaka är en liten textfil som webbplatsen du besöker begär att
              få spara på din dator.
            </Typography>

            <Typography
              component={'p'}
              variant={'body2'}
              align={'center'}
              paragraph>
              I BTJ-appen används kakor i så liten utsträckning som möjligt, och
              endast för att förbättra tjänstens innehåll och
              användarupplevelsen.
            </Typography>

            <Typography
              component={'p'}
              variant={'body2'}
              align={'center'}
              paragraph>
              Kakor används för att kunna:
              <ul>
                <li>
                  Hantera inloggning, utan kakor går det inte att logga in
                </li>
                <li>Anonymt följa beteende via Google Analytics</li>
              </ul>
            </Typography>

            <Typography
              component={'p'}
              variant={'body2'}
              align={'center'}
              paragraph>
              Om du har några frågor angående hur vi hanterar kakor eller{' '}
              <Link href='https://www.btj.se/personuppgifter'>
                personuppgifter
              </Link>
              , kontakta{' '}
              <Link href='mailto:btj-appen@btj.se'>btj-appen@btj.se</Link>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Slide>
  );
}

export default CookiePolicy;

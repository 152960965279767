import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import OrderHistoryValues from '../../../types/OrderHistoryValues.type';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {},
  })
);

function OrderCard(values: OrderHistoryValues) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container
          justify={'flex-start'}
          alignItems={'flex-start'}
          spacing={1}>
            {values.orderCreatedBy && (
            <Grid item>            
              <Typography variant='subtitle2' gutterBottom>
                  Beställare: {values.orderCreatedBy}
              </Typography>
              </Grid>
            )}
          </Grid>
        <Grid
          container
          justify={'flex-start'}
          alignItems={'flex-start'}
          spacing={3}>
          <Grid item>
            <Typography component={'h3'} variant='h6'>
              Best.datum
            </Typography>
            {values.orderCreated && (
              <Typography variant='subtitle2' gutterBottom>
                {values.orderCreated}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Typography component={'h3'} variant='h6'>
              Medietyp
            </Typography>
            {values.mediaType && (
              <Typography variant='subtitle2' gutterBottom>
                {values.mediaType}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography component={'h3'} variant='h6'>
              {values.mediaType === 'Bok' ? 'ISBN' : 'ISBN eller EAN'}
            </Typography>
            {values.isbn && (
              <Typography variant='subtitle2' gutterBottom>
                {values.isbn}
              </Typography>
            )}
          </Grid>
          {values.biblRecordId && values.biblRecordId.length > 0 &&
          <Grid item>
            <Typography component={'h3'} variant='h6'>
              Titelnummer
            </Typography>
            {values.biblRecordId && (
              <Typography variant='subtitle2' gutterBottom>
                {values.biblRecordId}
              </Typography>
            )}
          </Grid>
            }
          <Grid item>
            <Typography component={'h3'} variant='h6'>
              Språk
            </Typography>
            {values.languages && values.languages.length > 0 && (
              <Typography component={'p'} variant='subtitle2' gutterBottom>
                { values.languages }
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default OrderCard;

import { ImageSubmitModel } from '../types/SubmitValues.type';
import Image from '../types/Image.type';
import FormValues from '../types/FormValues.type';
import { IMAGE_SUBMISSION_FAILED } from '../Constants';

export default function mapImageUploadingResults(
  imageList: Array<Image>,
  uploadedImageList: any,
  formValues: FormValues
) {
  const errors: Array<string> = [];
  const images: Array<ImageSubmitModel> = [];

  imageList.forEach((item: Image, index: number) => {
    const { id, category } = item;

    //@ts-ignore
    if (!!uploadedImageList[index].value) {
      images.push({
        //@ts-ignore
        description: formValues[category][id].description,
        //@ts-ignore
        imageFileId: uploadedImageList[index].value,
      });
    } else {
      errors.push(
        //@ts-ignore
        `${formValues[category][id].description}: ${IMAGE_SUBMISSION_FAILED}`
      );
    }
  });

  return { images, errors };
}

export async function checkIfAuthorized(): Promise<boolean> {
  const res = await fetch('/api/v1/Identity/IsTitelpageUser', {
    method: 'GET',
  });
  return res.status === 200;
}

export function isSessionOutdated() {
  const loginRequired = localStorage.getItem('login-required');

  return loginRequired === 'true';
}

export function removeSessionStatus() {
  localStorage.removeItem('login-required');
}

import FormValues, { MediaType } from '../../types/FormValues.type';

export const initialMiscValues: FormValues = {
  illustrations: [],
  parallelText: false,
  unknownLanguage: false,
  mediaType: MediaType.Misc,
  isbn: '',
  noIsbn: false,
  hasAppendixes: true,
  notes: '',
  images: [],
  appendixes: [{ file: null, description: 'Bild 1' }],
  numberOfLabels: null,
  labels: false,
  isMiscPage: true,
};

import { MediaType } from '../types/FormValues.type';


export default function getMediaName(media?: MediaType){
  switch (media) {
    case MediaType.Book : return 'boken'
    case MediaType.Music : return 'objektet'
    case MediaType.Film : return 'filmen'
    case MediaType.VideoGame : return 'spelet'
    case MediaType.Misc : return 'objektet'
    default: return 'objektet';
  }
}

//@ts-nocheck
import React, { useEffect, useRef, useCallback, useState, useContext } from 'react';
import Webcam from 'react-webcam';
import GAEvent from '../helpers/GAEvent';
import BtjContext from '../context/BtjContext';

import {
  IconButton,
  makeStyles,
  createStyles,
  Theme,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import base64ToFile from '../helpers/base64ToFile';
import { getBookReviewByOCR } from '../services/BookReview/BookReview.service';
import LoadingModal from './LoadingModal';
import LoadingType from '../types/LoadingType';
import compressImage from '../helpers/compressImage';

const sizeConstraint = 4000000;

const CANVAS_SIZE = {
  WIDTH: 320,
  HEIGHT: 430,
};

const crossHairSvg =
  'M77.125 148.02567c0-3.5774 2.73862-6.27567 6.37076-6.27567H119V117H84.0192C66.50812 117 52 130.77595 52 148.02567V183h25.125v-34.97433zM237.37338 117H202v24.75h35.18494c3.63161 0 6.69006 2.69775 6.69006 6.27567V183H269v-34.97433C269 130.77595 254.88446 117 237.37338 117zM243.875 285.4587c0 3.5774-2.73863 6.27567-6.37076 6.27567H202V317h35.50424C255.01532 317 269 302.70842 269 285.4587V251h-25.125v34.4587zM83.49576 291.73438c-3.63213 0-6.37076-2.69776-6.37076-6.27568V251H52v34.4587C52 302.70842 66.50812 317 84.0192 317H119v-25.26563H83.49576z';

const videoConstraints = {
  facingMode: 'environment',
  height: 1080,
  width: 1920,
};

type OCRReaderProps = {
  handleOCRCode: Function;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '15px auto',
      textAlign: 'center',
      position: 'relative',
    },
    video: {
      borderRadius: '6px',
      boxShadow: 'rgb(230 230 230) 0px 0px 0px 1px inset',
      objectFit: 'cover',
    },
    canvas: {
      position: 'absolute',
      top: -32,
      left: 0,
    },
    shutter: {
      position: 'absolute',
      bottom: '0.3rem',
      width: '100%',
      height: '4rem',
      background: '#f5f5f5',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      borderRadius: 0,
      borderBottomRightRadius: '6px',
      borderBottomLeftRadius: '6px',
      opacity: 0.85,
    },
    shutterBtn: {
      flex: 1,
      fontSize: '0.875rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: 'black',
      fontFamily: 'futura-pt',
      '&:hover': {
        borderRadius: 0,
        backgroundColor: 'unset',
      },
    },
  })
);

function OCRReader({ handleOCRCode }: OCRReaderProps) {
  const classes = useStyles();
  const [notFound, setNotFound] = useState(false);
  const webcamRef = useRef(null);
  const canvas = useRef(null);

  const [error, setError] = useState(null);
  const [screenshot, setScreenshot] = useState<File | null>(null);
  const { userProfile } = useContext(BtjContext);

  const registerGAEvent = (success:string) => {
    GAEvent('Enter isbn', {
      customerName: userProfile ? userProfile.customerName : 'anonymous',
      appenService: 'Recensionsbanken',
      actionDetail: 'ocr',
      success,
    });
  }

  useEffect(() => {
    canvas.current.width = CANVAS_SIZE.WIDTH;
    canvas.current.height = CANVAS_SIZE.HEIGHT;
    const _canvas = canvas.current.getContext('2d');
    _canvas.drawImage(
      webcamRef.current.video,
      0,
      0,
      CANVAS_SIZE.WIDTH,
      CANVAS_SIZE.HEIGHT,
      0,
      0,
      CANVAS_SIZE.WIDTH,
      CANVAS_SIZE.HEIGHT
    );
    _canvas.fillStyle = 'rgba(255,255,255,0.4)';
    const shape = new Path2D(crossHairSvg);
    _canvas.fill(shape);
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const processISBN = async () => {
      const file =
        screenshot.size < sizeConstraint
          ? screenshot
          : await compressImage(screenshot);

      setTimeout(() => {
        abortController.abort();
      }, 5000);

      getBookReviewByOCR(file, abortController.signal).then((code) => {
        if (!!code) {
          registerGAEvent('success');
          handleOCRCode(code);
        } else {
          registerGAEvent('failure');
          setError(code);
          setNotFound(true);
          setScreenshot(null);
        }
      });
    };

    if (!!screenshot) {
      processISBN();
    }

    return () => {
      abortController.abort();
    };
  }, [screenshot]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const file = base64ToFile(imageSrc);

    setScreenshot(file);
  }, [webcamRef]);

  const handleSnackbarClose = () => {
    setNotFound(false);
  };

  return (
    <>
      <LoadingModal loadingType={LoadingType.Review} isLoading={!!screenshot} />
      <Snackbar
        open={notFound}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          variant='filled'
          severity={error === null ? 'info' : 'error'}>
          {error === null
            ? 'Skanningen misslyckades! Försök igen eller prova en annan metod.'
            : 'Någonting gick fel! Försök igen'}
        </Alert>
      </Snackbar>
      <div className={classes.root}>
        <canvas ref={canvas} className={classes.canvas} />
        <Webcam
          className={classes.video}
          height={CANVAS_SIZE.HEIGHT}
          width={CANVAS_SIZE.WIDTH}
          ref={webcamRef}
          audio={false}
          screenshotFormat='image/jpeg'
          videoConstraints={videoConstraints}
          forceScreenshotSourceSize={true}
        />
        <div className={classes.shutter}>
          <IconButton
            className={classes.shutterBtn}
            aria-label='shutter'
            onClick={() => {
              capture();
            }}>
            Tryck för att skanna
          </IconButton>
        </div>
      </div>
    </>
  );
}

export default OCRReader;

//External dependencies
import React, { useState, useContext, useEffect } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import {
  Container,
  createStyles,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import { useFormik } from 'formik';

// Data & Types
import FormValues from '../../types/FormValues.type';
import { initialMusicValues } from './MusicOrderInitialvalues';
import MediaOrderValidationSchema from './MediaOrderValidation.schema';
import textContent from '../../assets/textContent.json';
//Helpers
import postForm from '../../services/Form.service';
import adaptFormValuesToPostValues from '../../helpers/formToPost.adapter';
import validateFields from '../../helpers/validateFields';
import mapImageUploadingResults from '../../helpers/mapImageUploadingResults';

// Components
import FormHeader from '../../components/FormHeader';
import FormNavigation from '../../components/FormNavigation';
import LoadingModal from '../../components/LoadingModal';
import MediaAppendixPage from './MediaAppendixPage';
import MediaContentPage from './MediaContentPage';
import MediaResultsPage from './MediaResultsPage';
import CustomRoutes from '../../components/CustomRoutes';
import BtjContext from '../../context/BtjContext';
import CustomSnackBar from '../../components/CustomSnackBar';
import {
  ORDER_SUBMISSION_FAILED,
  ORDER_SUBMITTED_SUCCESSFULLY,
} from '../../Constants';

const ROUTES = ['content', 'appendixes', 'results'];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      display: 'none',
    },
    formPaper: {
      border: 'none',
      minHeight: '100vh',
      paddingTop: theme.spacing(12),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      '& form': {
        flex: 1,
      },
    },
  })
);

function MusicOrder() {
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    resetForm,
    setErrors,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormik<FormValues>({
    initialValues: initialMusicValues,
    validationSchema: MediaOrderValidationSchema,
    onSubmit: submitForm,
    validateOnBlur: false,
    validateOnChange: false,
  });
  const [activeFields, setActiveFields] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackbarStatus, setSnackbarStatus] = useState<{
    severity: 'success' | 'info' | 'warning' | 'error';
    text: any;
    isShowing: boolean;
  }>({
    severity: 'info',
    text: '',
    isShowing: false,
  });
  const resetErrors = () => setErrors({});
  const navigate = useNavigate();
  const { promiseList, setPromiseList } = useContext(BtjContext);

  useEffect(() => {
    return () => {
      setPromiseList([]);
    };
  }, []);

  async function submitForm(values: FormValues) {
    setIsLoading(true);

    Promise.allSettled(promiseList.map((item) => item.promise))
      .then((result) => {
        const { images, errors } = mapImageUploadingResults(
          promiseList,
          result,
          values
        );

        if (!(errors.length > 0)) {
          postForm(adaptFormValuesToPostValues(values, images)).then(
            (result) => {
              setTimeout(() => {
                setIsLoading(false);
                if (result.status === 200) {
                  setSnackbarStatus({
                    severity: 'success',
                    text: ORDER_SUBMITTED_SUCCESSFULLY,
                    isShowing: true,
                  });
                } else {
                  setSnackbarStatus({
                    severity: 'error',
                    text: ORDER_SUBMISSION_FAILED,
                    isShowing: true,
                  });
                }
              }, 1000);
            }
          );
        } else {
          throw errors;
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setSnackbarStatus({
          severity: 'error',
          text: Array.isArray(error) ? error : ORDER_SUBMISSION_FAILED,
          isShowing: true,
        });
      });
  }

  function resetValues(initialValues: FormValues) {
    const initialImageDescriptions = initialValues.images.map(
      (value) => value.description
    );
    const nextImages = [...values.images];
    nextImages.forEach((image, index) => {
      image.file = null;
      delete image.imageFileId;
      image.description = initialImageDescriptions[index];
    });
    setFieldValue('images', nextImages);
    setFieldValue('appendixes', []);
    setFieldValue('illustrations', []);
    resetForm({
      values: {
        ...initialValues,
        images: nextImages,
        appendixes: [],
        illustrations: [],
      },
    });
  }

  function blurAndValidate(event: React.FocusEvent<HTMLInputElement>) {
    handleBlur(event);
    validateFields(
      MediaOrderValidationSchema,
      values,
      [event.target.name],
      setFieldTouched,
      setFieldError
    );
  }

  function changeAndValidate(event: React.ChangeEvent<HTMLInputElement>) {
    handleChange(event);
    //@ts-ignore
    touched[event.target.name] &&
      validateFields(
        MediaOrderValidationSchema,
        values,
        [event.target.name],
        setFieldTouched,
        setFieldError
      );
  }

  function validate(targetPaths: Array<string>) {
    validateFields(
      MediaOrderValidationSchema,
      values,
      targetPaths,
      setFieldTouched,
      setFieldError
    );
  }

  function handleSnackbarClose() {
    if (snackbarStatus.severity === 'success') {
      resetValues(initialMusicValues);
      navigate('/');
    }
    setSnackbarStatus({ ...snackbarStatus, isShowing: false });
  }

  const formPageProps = {
    errors,
    onBlur: blurAndValidate,
    onChange: changeAndValidate,
    setActiveFields,
    setFieldValue,
    setFieldError,
    touched,
    validate,
    values,
    textContent: textContent.musicOrder,
  };

  return (
    <Container maxWidth={'sm'}>
      <LoadingModal isLoading={isLoading} />
      {snackbarStatus.isShowing && (
        <CustomSnackBar
          type={snackbarStatus.severity}
          message={snackbarStatus.text}
          onClose={handleSnackbarClose}
        />
      )}
      <Paper className={classes.formPaper}>
        <FormHeader
          resetForm={() => resetValues(initialMusicValues)}
          textContent={'NY BESTÄLLNING: MUSIK'}
        />
        <CustomRoutes>
          <Route
            path='content'
            element={<MediaContentPage {...formPageProps} />}
          />
          <Route
            path='appendixes'
            element={<MediaAppendixPage {...formPageProps} />}
          />
          <Route
            path='results'
            element={<MediaResultsPage {...formPageProps} />}
          />
        </CustomRoutes>
        <FormNavigation
          resetErrors={resetErrors}
          activeFields={activeFields}
          errors={errors}
          routes={ROUTES}
          submit={() => submitForm(values)}
          validateFields={(targetPaths) =>
            validateFields(
              MediaOrderValidationSchema,
              values,
              targetPaths,
              setFieldTouched,
              setFieldError
            )
          }
        />
      </Paper>
    </Container>
  );
}

export default MusicOrder;

import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Accordion, AccordionSummary } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Field } from '../types/BookReview';

const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    border: 'none',
    flex: 1,
    margin: '0 !important',
    position: 'relative',
    '&:before': {
      background: 'none !important',
    },
  },
  accordionRoot: {
    padding: 0,
    minHeight: '0 !important',
    '&:first-child': {
      maxWidth: '95%',
    },
  },
  accordionContent: {
    margin: '0 !important',
  },
  expandIconWrapper: {
    padding: 0,
    position: 'absolute',
    right: 0,
    top: '.75rem',
  },
  acordionDetails: {
    padding: 0,
    margin: 0,
    '&:first-child': {
      maxWidth: '95%',
    },
  },
  labelText: {
    fontWeight: 'bold',
    marginRight: '.5rem',
    textTransform: 'capitalize',
  },
  spanText: {
    flex: 1,
  },
}));

const FieldRow = ({ name, content, foldable }: Field) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const classes = useStyles();

  return (
    <>
      <Typography
        className={classes.labelText}
        component={'label'}
        variant={'body2'}>
        {name}:
      </Typography>
      {!foldable ? (
        <Typography
          className={classes.spanText}
          component={'span'}
          variant={'body2'}>
          {content}
        </Typography>
      ) : (
        <Accordion className={classes.accordion}>
          <AccordionSummary
            classes={{
              root: classes.accordionRoot,
              content: classes.accordionContent,
              expandIcon: classes.expandIconWrapper,
            }}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            expandIcon={
              <ExpandMoreIcon style={{ padding: 0, position: 'absolute' }} />
            }>
            <Typography component={'span'} variant={'body2'}>
              {!isExpanded
                ? `${!!content && content.substring(0, 100)}...`
                : content}
            </Typography>
          </AccordionSummary>
        </Accordion>
      )}
    </>
  );
};

export default FieldRow;

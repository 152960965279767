export const ORDER_SUBMITTED_SUCCESSFULLY = 'Beställning skickad!';
export const ORDER_SUBMISSION_FAILED =
  'Något gick fel! Försök igen och kontakta BTJ om felet kvarstår';
export const IMAGE_SUBMISSION_FAILED =
  'Något gick fel vid uppladdning av bilden. Försök igen!';

export const PrivateRoutes = [
  '/order',
  '/order/book/isbn',
  '/order/book/language',
  '/order/book/content',
  '/order/book/photos',
  '/order/book/appendixes',
  '/order/book/results',
  '/order/game/content',
  '/order/game/appendixes',
  '/order/game/results',
  '/order/music/content',
  '/order/music/appendixes',
  '/order/music/results',
  '/order/movie/content',
  '/order/movie/appendixes',
  '/order/movie/results',
  '/order/misc/content',
  '/order/misc/results',
  '/order/history',
  '/bookreview',
  '/bookreview/result',
  '/account',
];

import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
      borderStyle: 'none',
    },
    bottomLine: {
      width: '60%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
  }),
);

type Props = {
  header: string;
  children: ReactNode;
};

function ResultsTableBlock({ header, children }: Props) {
  const classes = useStyles();
  return (
    <>
      <TableContainer className={classes.root} component={Paper}>
        <Table aria-label={`Sammanfattning av ${header}`}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} component='td' scope='row' align={'left'}>
                <Typography variant='h2' component='h3'>
                  {header}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default ResultsTableBlock;

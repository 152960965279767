import FormValues from '../types/FormValues.type';

export default function handleImageDescriptionChange(
  value:string,
  index: number,
  setFieldValue: (targetPath: string, values: any) => void,
  values: FormValues,
  targetPath:string='appendixes'
) {
    const newImages = [...values.appendixes];
      newImages[index] = {
        ...newImages[index],
        description: value,
      };
      setFieldValue(targetPath || 'appendixes', newImages);

}

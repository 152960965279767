import { AuthProvider, AuthProviderProps, UserManager } from 'oidc-react';
import { IDENTITY_CONFIG, getBaseUrl } from './AuthenticationConfig';
import fetchIntercept from 'fetch-intercept';
import { useEffect, useState } from 'react';
import {
  isSessionOutdated,
  removeSessionStatus,
} from '../services/Auth.service';
import ExpiredSession from '../components/ExpiredSession';
import { PrivateRoutes } from '../Constants';

const userManager = new UserManager({
  ...IDENTITY_CONFIG,
});

const oidcConfig: AuthProviderProps = {
  userManager: userManager,
  autoSignIn: false,
  onSignOut: userManager.revokeAccessToken,
  onSignIn: () => {
    window.history.pushState(null, '', getBaseUrl());
  },
};

const AuthenticationProvider = (props: React.PropsWithChildren<{}>) => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const checkLoginRequired = async () => {
    if (
      isSessionOutdated() &&
      PrivateRoutes.findIndex((route) => route === window.location.pathname) !==
        -1
    ) {
      setIsSessionExpired(true);
    } else if (
      isSessionOutdated() &&
      window.location.pathname.includes('/news')
    ) {
      removeSessionStatus();
      await userManager.removeUser();
    }
  };

  userManager.events.addSilentRenewError(() => {
    checkLoginRequired();
  });

  userManager.events.addAccessTokenExpired(() => {
    checkLoginRequired();
  });

  //add interceptors
  useEffect(() => {
    const unregister = fetchIntercept.register({
      request: async (url, config) => {
        const user = await userManager.getUser();
        let accessToken = user?.access_token;

        if (user?.expired) {
          //manual token renew will take place only if automatic hasnt been executed.
          await userManager.signinSilent();
          const newUser = await userManager.getUser();
          accessToken = newUser?.access_token;
        }

        const newConfig = {
          ...config,
          ...{
            headers: {
              ...config.headers,
              Authorization: `Bearer ${accessToken}`,
            },
          },
        };

        return [url, newConfig];
      },
    });

    return () => {
      unregister();
    };
  }, []);

  useEffect(() => {
    const removeUser = () => {
      if (isSessionOutdated()) {
        removeSessionStatus();
        userManager.removeUser();
      }
    };

    window.addEventListener('beforeunload', removeUser);
    return () => window.removeEventListener('beforeunload', removeUser);
  }, []);

  return (
    <AuthProvider {...oidcConfig}>
      {props.children}
      {isSessionExpired && <ExpiredSession open={isSessionExpired} />}
    </AuthProvider>
  );
};
export default AuthenticationProvider;

import * as yup from 'yup';

const MiscOrderValidationSchema = yup.object().shape({
  // CONTENT PAGE
  isbn: yup
    .string()
    .trim()
    .min(1, 'Om du angett att produkten har ISBN eller EAN, vänligen skriv in eller skanna värdet')
    .max(100, 'För många tecken')
    .nullable().notRequired(),

  images: yup.array().nullable().required('Du måste bifoga bilder på objektet'),

  // APPENDIXES PAGE
  appendixes: yup.array().nullable().required('Du måste bifoga bilder på objektet'),
  hasAppendixes: yup.mixed()
    .oneOf([true, false], 'Du måste ange om verket har bilagor e.d.')
    .required('Du måste ange om verket har bilagor e.d.'),

  // RESULTS PAGE
  notes: yup
    .string()
    .trim()
    .max(2000, 'Kommentaren får inte vara längre än 2000 tecken.')
    .nullable(true)
    .notRequired(),
  numberOfLabels: yup
    .number()
    .transform(value => (isNaN(value) ? 0 : value))
    .required('Du måste ange antal etiketter.')
    .integer('Antal etiketter måste vara ett heltal')
    .min(1, 'Du måste ange antal etiketter.')
    .max(100, 'Du kan som mest beställa 100 etiketter per objekt.')
    .nullable()
    .notRequired(),
});

export default MiscOrderValidationSchema;

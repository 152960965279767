import React, { ChangeEvent, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import PhotoAccordion from '../../../components/PhotoAccordion';
import handleImageFileChange from '../../../helpers/handleImageFileChange';
import FormPage from '../../../components/FormPage';
import RadioButtons from '../../../components/RadioButtons';
import FormPageProps from '../../../types/FormPageProps.type';
import handleImageDescriptionChange from '../../../helpers/handleImageDescriptionChange';
import flikar from '../../../assets/images/flikar.jpg';
import castJSONString from '../../../helpers/castJSONString';
import BtjContext from '../../../context/BtjContext';
import Image from '../../../types/Image.type';

function AppendixForm({
  values,
  touched,
  errors,
  onBlur,
  setFieldValue,
  setFieldError,
  setActiveFields,
  textContent,
}: FormPageProps) {
  const {
    pageHeader,
    pageInstruction,
    helperHeader,
    helperItems,
  } = textContent.appendixForm;
  helperItems[0].imageURL = flikar;
  const { setPromiseList } = useContext(BtjContext);

  useEffect(() => {
    setActiveFields(['appendixes', 'hasAppendixes']);
  }, [setActiveFields]);

  useEffect(() => {
    if (
      values.appendixes.length < 10 &&
      values.appendixes[values.appendixes.length - 1] &&
      values.appendixes[values.appendixes.length - 1].file !== null
    ) {
      setFieldValue('appendixes', [
        ...values.appendixes,
        {
          file: null,
          description: `Bilaga/egenskap(${values.appendixes.length + 1})`,
        },
      ]);
    }
  }, [values.appendixes, setFieldValue]);

  function handleRadioChange(event: ChangeEvent<HTMLInputElement>) {
    setFieldError(event.target.name, undefined);
    setFieldValue(event.target.name, event.target.value === 'true');
  }

  function handleHasAppendixesChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (event.target.value === 'true') {
      setFieldValue('appendixes', [
        { file: null, description: 'Bilaga/egenskap' },
      ]);
    }
    if (event.target.value === 'false') {
      setFieldValue('appendixes', []);

      setPromiseList((value: Array<Image>) => [
        ...value.filter((item: Image) => item.category !== 'appendixes'),
      ]);
    }
    handleRadioChange(event);
  }

  return (
    <FormPage
      header={pageHeader}
      instruction={pageInstruction}
      helperHeader={helperHeader}
      helperItems={helperItems}>
      <Grid item>
        <RadioButtons
          name={'hasAppendixes'}
          text={'Har boken bilagor eller andra egenskaper?'}
          value={values.hasAppendixes}
          error={errors.hasAppendixes}
          touched={touched.hasAppendixes}
          onChange={(event) => handleHasAppendixesChange(event)}
          onBlur={onBlur}
          alternatives={[
            {
              label: 'Ja, det finns bilagor eller andra egenskaper',
              value: true,
            },
            { label: 'Nej', value: false },
          ]}
        />
      </Grid>
      {values.hasAppendixes && (
        <Grid item>
          <PhotoAccordion
            images={values.appendixes}
            errors={errors.appendixes && castJSONString(errors.appendixes)}
            handleImageChange={(event, index, setPromiseList) =>
              handleImageFileChange(
                values,
                'appendixes',
                setFieldValue,
                event,
                index,
                setPromiseList
              )
            }
            handleDescriptionChange={(value, index: number) =>
              handleImageDescriptionChange(value, index, setFieldValue, values)
            }
            commentSection
          />
        </Grid>
      )}
    </FormPage>
  );
}

export default AppendixForm;

import FormValues, { MediaType } from '../../types/FormValues.type';

export const initialBookValues: FormValues = {
  mediaType: MediaType.Book,
  isbn: '',
  noIsbn: false,
  hasAppendixes: null,
  notes: '',
  hasIllustrations: null,
  images: [
    { file: null, description: 'Framsida' },
    { file: null, description: 'Baksida' },
    { file: null, description: 'Titelsida' },
    { file: null, description: 'Kolofon' },
  ],
  illustrations: [],
  appendixes: [],
  numberOfLabels: null,
  unknownLanguage: null,
  languages: [],
  labels: false,
  numberOfPages: '',
  paginationUnknown: false,
  paginationMissing: false,
  parallelText: false,
  spiralBinding: null,
  tableOfContents: null,
};

import React, { ReactNode } from 'react';
import { Grid, Slide, Typography } from '@material-ui/core';
import HelpButton, { HelperItem } from './HelpButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

type Props = {
  header: string;
  instruction: string;
  children: ReactNode;
  helperItems?: HelperItem[];
  helperHeader?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: '5%'
    },
    header: {
      paddingBottom: '5%'
    },
  }),
);
function FormPage({ header, helperHeader = 'Vanliga frågor', instruction, children, helperItems }: Props) {
  const classes = useStyles();
  return (
    <Slide direction="left" in={true}>
    <form>
      <Grid container wrap={'nowrap'} direction={'column'} justify={'flex-start'} className={classes.root}>
        <Grid item container direction={'row'} className={classes.header}  alignItems={'center'} justify={'center'}>
        <Grid item xs={11}>
          <Typography variant={'h1'} component={'h2'} paragraph>{header}</Typography>
        </Grid>
          <Grid item xs={1}>
            {helperItems && <HelpButton helperItems={helperItems} header={helperHeader} /> }
          </Grid>
          <Grid item xs={12}>
            <Typography paragraph variant={'body2'}>
              {instruction}
            </Typography>
          </Grid>
        </Grid>
        {children}
      </Grid>
    </form>
    </Slide>
  );
}

export default FormPage;

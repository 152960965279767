import React, { ChangeEvent, FocusEventHandler } from 'react';
import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Language } from '../types/Language.type';
import FormValues from '../types/FormValues.type';

type Props = {
  handleChange: (event: ChangeEvent<{}>, value: {}[]) => void;
  disabled?: boolean;
  handleBlur: FocusEventHandler<HTMLInputElement>;
  name: string;
  value: FormValues['languages'];
  languages: Array<Language>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autocomplete: {
      paddingTop: theme.spacing(2),
    },
  }),
);

function LanguageSelect({
                          name,
                          languages,
                          handleBlur,
                          handleChange,
                          disabled = false,
                          value,
                        }: Props) {
  const classes = useStyles();
  return (
    <>
      <Autocomplete
        onBlur={handleBlur}
        disabled={disabled}
        onChange={handleChange}
        options={languages}
        id='language-select'
        className={classes.autocomplete}
        value={value}
        autoHighlight
        multiple
        filterSelectedOptions
        getOptionSelected={(option, value) => option.code === value.code}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            label='Välj språk'
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
    </>
  );
}

export default LanguageSelect;

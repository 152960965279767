import React, { useContext, useEffect } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import BookOrder from './views/BookOrder/BookOrder';
import NewOrder from './views/NewOrder';
import OrderHistory from './views/OrderHistory';
import MyLibrary from './views/MyLibrary';
import NoSubscription from './components/NoSubscription';
import MusicOrder from './views/MediaOrder/MusicOrder';
import MovieOrder from './views/MediaOrder/MovieOrder';
import GameOrder from './views/MediaOrder/GameOrder';
import MiscOrder from './views/MiscOrder/MiscOrder';
import Home from './views/Home';
import BookReviewSearch from './views/BookReviewSearch';
import BookReview from './views/BookReview/BookReview';
import PrivateRoute from './components/PrivateRoute';
import Role from './auth/Role';
import BtjContext from './context/BtjContext';
import NotFound from './components/NotFound';
import CustomRoutes from './components/CustomRoutes';

import Cookies from 'universal-cookie';
import { useAuth } from 'oidc-react';
import CookiePolicy from './components/CookiePolicy';

function AppRoutes() {
  const auth = useAuth();
  const { userProfile } = useContext(BtjContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (userProfile === null) {
      navigate('nosubscription');
    }
  }, [userProfile, navigate]);

  useEffect(() => {
    const _cookies = new Cookies();

    if (!_cookies.get('btj-appen')) {
      auth.signOut();
    }
  }, [auth]);

  return (
    <CustomRoutes>
      <Route path='cookie-policy' element={<CookiePolicy />} />
      <Route path='notfound' element={<NotFound />} />
      <Route path='nosubscription' element={<NoSubscription />} />
      <Route path='/' element={<Home />} />
      <Route
        path='order'
        element={
          <PrivateRoute roles={[Role.TITLEPAGEUSER]}>
            <NewOrder />
          </PrivateRoute>
        }
      />
      <Route
        path='order/book/*'
        element={
          <PrivateRoute roles={[Role.TITLEPAGEUSER]}>
            <BookOrder />
          </PrivateRoute>
        }
      />
      <Route
        path='order/music/*'
        element={
          <PrivateRoute roles={[Role.TITLEPAGEUSER]}>
            <MusicOrder />
          </PrivateRoute>
        }
      />
      <Route
        path='order/movie/*'
        element={
          <PrivateRoute roles={[Role.TITLEPAGEUSER]}>
            <MovieOrder />
          </PrivateRoute>
        }
      />
      <Route
        path='order/misc/*'
        element={
          <PrivateRoute roles={[Role.TITLEPAGEUSER]}>
            <MiscOrder />
          </PrivateRoute>
        }
      />
      <Route
        path='order/game/*'
        element={
          <PrivateRoute roles={[Role.TITLEPAGEUSER]}>
            <GameOrder />
          </PrivateRoute>
        }
      />
      <Route
        path='order/history'
        element={
          <PrivateRoute roles={[Role.TITLEPAGEUSER]}>
            <OrderHistory />
          </PrivateRoute>
        }
      />
      <Route
        path='bookreview'
        element={
          <PrivateRoute roles={[Role.REVIEWBANKUSER]}>
            <BookReviewSearch />
          </PrivateRoute>
        }
      />
      <Route
        path='bookreview/result'
        element={
          <PrivateRoute roles={[Role.REVIEWBANKUSER]}>
            <BookReview />
          </PrivateRoute>
        }
      />
      <Route
        path='account'
        element={
          <PrivateRoute
            roles={[
              Role.TITLEPAGEUSER,
              Role.REVIEWBANKUSER,
              Role.CUSTOMERUSER,
            ]}>
            <MyLibrary />
          </PrivateRoute>
        }
      />
    </CustomRoutes>
  );
}

export default AppRoutes;

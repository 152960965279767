import React, {
  ChangeEventHandler,
  FocusEventHandler,
  useState,
  useEffect,
} from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import FormValues from '../types/FormValues.type';
import FormPageProps from '../types/FormPageProps.type';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CodeReaderModal from './CodeReaderModal';

type Props = {
  error: string | undefined;
  isbnValue: FormValues['isbn'];
  noIsbnValue: FormValues['noIsbn'];
  onChange: ChangeEventHandler;
  handleBlur: FocusEventHandler<HTMLInputElement>;
  handleNoIsbn: ChangeEventHandler<HTMLInputElement>;
  touched: boolean | undefined;
  setFieldValue: FormPageProps['setFieldValue'];
  setFieldError: FormPageProps['setFieldError'];
  setFieldTouched: FormPageProps['setFieldTouched'];
};

function EanField({
  error,
  isbnValue,
  noIsbnValue,
  onChange,
  touched,
  handleBlur,
  handleNoIsbn,
  setFieldValue,
  setFieldError,
  setFieldTouched,
}: Props) {
  const [openBCRModal, setOpenBCRModal] = useState(false);
  const [code, setCode] = useState<string | null>(null);

  useEffect(() => {
    if (code) {
      handleEANField(code.replace('OCR', ''), true, undefined);
      setCode(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, noIsbnValue]);

  const handleEANField = (
    value: string | null = null,
    touched: boolean = false,
    error: string | undefined = undefined
  ) => {
    setFieldValue('isbn', value);
    setFieldTouched && setFieldTouched('isbn', touched);
    setFieldError('isbn', error);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          onBlur={handleBlur}
          autoFocus={false}
          autoComplete={'off'}
          error={!noIsbnValue && !!error}
          helperText={
            !noIsbnValue && error
              ? error
              : 'Ange ISBN eller EAN genom att skanna med din enhets kamera eller mata in siffrorna manuellt. Verifiera att siffrorna stämmer överens med verket.'
          }
          name={'isbn'}
          type='text'
          label='ISBN eller EAN'
          value={isbnValue || ''}
          onChange={onChange}
          disabled={!!noIsbnValue}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  disabled={!!noIsbnValue}
                  aria-label='Scanner'
                  onClick={() => {
                    handleEANField(null, false, undefined);
                    setOpenBCRModal(true);
                  }}>
                  <CameraAltIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {openBCRModal ? (
          <CodeReaderModal
            open={openBCRModal}
            onClose={setOpenBCRModal}
            onCodeRead={setCode}
          />
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!noIsbnValue}
              onChange={handleNoIsbn}
              name='noISBN'
              color='secondary'
            />
          }
          label={
            <Typography variant={'h3'} component={'span'}>
              ISBN eller EAN saknas
            </Typography>
          }
        />
      </Grid>
    </Grid>
  );
}

export default EanField;

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import { Review } from '../../../types/BookReview';
import Sanitized from '../../../components/Sanitized';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    border: 'none',
    borderRadius: 0,
    paddingBottom: '6rem',
    paddingTop: '.5rem',
  },
  title: { marginBottom: '1.5rem' },
}));

type BookDescriptionProps = {
  contentDescriptions: Array<Review>;
};

function BookDescription({ contentDescriptions }: BookDescriptionProps) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography className={classes.title} variant={'h2'} component={'h2'}>
        Beskrivning
      </Typography>
      {contentDescriptions.length > 0 ? (
        contentDescriptions.map(({ text }, index) => {
          return (
            <Typography key={index} variant={'body2'} component={'p'}>
              <Sanitized html={text} />
            </Typography>
          );
        })
      ) : (
        <Typography variant={'body2'} component={'h3'}>
          För denna titel finns inget Beskrivning
        </Typography>
      )}
    </Paper>
  );
}

export default BookDescription;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, TableRow, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
        backgroundColor: 'rgb(208 120 56 / 5%)',
      },
      '& td': {
        borderBottom: 'none',
      },
    },
  })
);

export default function ShadedTableRow(props: { children: React.ReactNode }) {
  const classes = useStyles();
  return <TableRow className={classes.root}>{props.children}</TableRow>;
}

import { createMuiTheme } from '@material-ui/core/styles';
import { responsiveFontSizes } from '@material-ui/core';

/* NOTE:
  special fonts are imported in the fonts.css and accessed through their names.
  BTJ uses futura-pt and freight-text-pro
*/

const headerTypes = ['futura-pt', 'Helvetica Neue', 'Arial', 'sans-serif'].join(
  ','
);
const bodyTypes = ['freight-text-pro', '"Times New Roman"', 'serif'].join(',');

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    btjPink: Palette['primary'];
  }

  interface PaletteOptions {
    btjPink: PaletteOptions['primary'];
  }
}
const lightTheme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: '#00635e',
        light: '#33827e',
        dark: '#004541',
        contrastText: '#fff',
      },
      secondary: {
        main: '#e94e2c',
        light: '#ed7156',
        dark: '#a3361e',
        contrastText: '#fff',
      },
      btjPink: {
        main: '#FDECE8',
        contrastText: '#000',
      },
    },
    typography: {
      fontFamily: headerTypes,
      h1: {
        fontSize: 36,
        fontWeight: 400,
        fontFamily: headerTypes,
      },
      h2: {
        fontSize: 26,
        fontWeight: 600,
        fontFamily: headerTypes,
      },
      h3: {
        fontSize: 24,
        fontFamily: headerTypes,
      },
      h4: {
        fontSize: 18,
        fontWeight: 700,
        fontFamily: headerTypes,
      },
      h5: {
        fontSize: 12,
        fontWeight: 600,
        fontFamily: headerTypes,
      },
      body1: {
        fontSize: 18,
        fontFamily: bodyTypes,
      },
      body2: {
        fontSize: 16,
        fontFamily: bodyTypes,
      },
      //   tag:{

      //   }
    },
    props: {
      MuiTextField: {
        variant: 'outlined',
      },
      MuiButton: {
        disableElevation: true,
        variant: 'outlined',
        color: 'primary',
      },
      MuiPaper: {
        variant: 'outlined',
      },
    },
  })
);

export default lightTheme;

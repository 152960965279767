import FormValues, { ImageFileViewModel } from '../types/FormValues.type';

export default function validateImages(
  values: FormValues,
  targetPath: string
): string | undefined {
  let errors: Array<string | null> = [];
  let error: string | null = null;

  //@ts-ignore
  values[targetPath].forEach((image: ImageFileViewModel, index: number) => {
    const itemName = (targetPath: string) => {
      if (targetPath === 'images') return 'verket';
      else if (targetPath === 'illustrations') return 'illustrationen';
      else if (targetPath === 'appendixes') return 'bilagan/egenskapen';
    };
    if (!image.description || image.description.trim().length === 0) {
      error = `Du måste ange beskrivning av ${itemName(targetPath)}.`;
    }
    if (image.file === null) {
      if (targetPath === 'images' || index === 0)
        error = `${image.description}: ${
          !values.isMiscPage
            ? ` Du behöver lägga till ett fotografi av ${itemName(targetPath)}.`
            : 'Du behöver lägga till minst ett fotografi av objektet.'
        }`;
    }

    errors.push(error);
    error = null;
  });

  return !errors.every((e) => !e) ? JSON.stringify(errors) : undefined;
}

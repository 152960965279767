import { createContext } from 'react';
import { BookReview } from '../types/BookReview';
import UserProfile from '../types/UserProfile.type';
import Image from '../types/Image.type';

type contextType = {
  userProfile: undefined | null | UserProfile;
  review: null | BookReview;
  setReview: Function;
  loading: boolean | null;
  modalTab: string | boolean;
  setModalTab: Function;
  promiseList: Array<Image>;
  setPromiseList: Function;
};

const initialValue: contextType = {
  userProfile: undefined,
  review: null,
  setReview: () => {},
  loading: false,
  modalTab: false,
  setModalTab: () => {},
  promiseList: [],
  setPromiseList: () => {},
};

const BtjContext = createContext<contextType>(initialValue);

export default BtjContext;

import Compress from 'browser-image-compression';

export default async function compressImage(file: File) {
  const options = {
    maxSizeMB: 4,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  try {
    const compressedFile = await Compress(file, options);

    return compressedFile;
  } catch (error) {
    return file;
  }
}

import { Language } from '../types/Language.type';

async function getLanguagesFromApi(
  signal: AbortSignal
): Promise<Language[] | undefined> {
  const res = await fetch('/api/v1/titlepage/language', {
    signal: signal,
    method: 'GET',
  });
  if (res.status === 200) {
    return res.json();
  }
}

export default getLanguagesFromApi;

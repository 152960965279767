import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import HelpButton from '../../components/HelpButton';
import textContent from './../../assets/textContent.json';
import BtjContext from '../../context/BtjContext';

function UserInfo() {
  const { userProfile } = useContext(BtjContext);
  const { helperHeader, helperItems } = textContent.myLibrary;

  return (
    <Grid container spacing={1} direction={'column'}>
      {userProfile && (
        <Grid
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          item>
          <Typography component={'h1'} variant='h2' paragraph>
            Du är inloggad som:
          </Typography>
          <div style={{ marginBottom: '1rem' }}>
            <HelpButton helperItems={helperItems} header={helperHeader} />
          </div>
        </Grid>
      )}
      <Grid item>
        <Typography component={'p'} variant='subtitle1'>
          {userProfile?.logonName}
        </Typography>
      </Grid>
      <Grid item>
        <Typography component={'p'} variant='subtitle1'>
          {userProfile?.emailAddress}
        </Typography>
      </Grid>
      <Grid item>
        <Typography component={'p'} variant='subtitle1'>
          {userProfile?.customerName}
        </Typography>
      </Grid>
      <Grid item>{userProfile && <hr />}</Grid>
    </Grid>
  );
}

export default UserInfo;

import React, { useContext, useEffect, useState } from 'react';

import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import QuotaInfo from './QuotaInfo';
import ExcessInfo from './ExcessInfo';
import PricesInfo from './PricesInfo';

import LibraryInfoValues from '../../types/LibraryInfoValues.type';
import PriceInfo from '../../types/PriceInfo.type';
import Quota from '../../types/QuotaInfo.type';

import getPriceInfoFromApi from '../../services/MyLibrary/PriceInfo.service';
import getQuotaExcessFromApi from '../../services/MyLibrary/QuotaExcess.service';
import getQuotaInfoFromApi from '../../services/MyLibrary/QuotaInfo.service';

import BtjContext from '../../context/BtjContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sigel: {
      lineHeight: '1.25rem',
    },
  })
);

function UsageInformation() {
  const classes = useStyles();
  const { userProfile } = useContext(BtjContext);
  const [prices, setPrices] = useState<PriceInfo[]>();
  const [excesses, setExcesses] = useState<LibraryInfoValues[]>();
  const [quotas, setQuotas] = useState<Quota>();

  useEffect(() => {
    getPriceInfoFromApi().then(
      (newPrices) => newPrices && setPrices(newPrices)
    );
    getQuotaExcessFromApi().then(
      (newExcesses) => newExcesses && setExcesses(newExcesses)
    );
    getQuotaInfoFromApi().then(
      (newQuotas) => newQuotas && setQuotas(newQuotas)
    );
  }, []);

  const showQuota = (quotas && quotas.prepaidQuota > 0) || false;

  return (
    <>
      <Grid item>
        <Typography component={'h1'} variant='h2' paragraph>
          Katalogiseringsinformation för&nbsp;
          {userProfile?.catalogingServiceOrganisationName ?? ''}
        </Typography>
      </Grid>

      {userProfile?.sigel && (
        <Grid item>
          <Typography
            className={classes.sigel}
            component={'p'}
            variant={'subtitle1'}>
            Sigel i Libris för beståndsuppdatering:&nbsp;
            <b>{userProfile?.sigel}</b>
          </Typography>
        </Grid>
      )}

      <Grid item>
        <hr />
      </Grid>

      {quotas && quotas.prepaidQuota > 0 && <QuotaInfo {...quotas} />}

      {excesses && excesses.length && (
        <ExcessInfo excesses={excesses} showQuota={showQuota} />
      )}

      {prices && prices?.length && (
        <PricesInfo prices={prices} showQuota={showQuota} />
      )}
    </>
  );
}

export default UsageInformation;

import React, { useContext, useEffect } from 'react';
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import BtjContext from '../context/BtjContext';
import Role from '../auth/Role';
import LoadingModal from '../components/LoadingModal';
import LoadingType from '../types/LoadingType';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainButton: {
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'initial',
      fontSize: '1rem',
      borderRadius: '25px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    noAccessButton: {
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'initial',
      fontSize: '1rem',
      borderRadius: '25px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      filter: 'brightness(1.5)',
    },
    subButtons: {
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'initial',
      fontSize: '1rem',
      borderRadius: '25px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      backgroundColor: 'rgba(255,255,255,0.7)',
    },
    logoutButton: {
      textTransform: 'initial',
      paddingTop: theme.spacing(8),
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '1rem',
    },
  })
);

function Dashboard() {
  const classes = useStyles();
  const auth = useAuth();
  const { userProfile, review, setReview } = useContext(BtjContext);

  useEffect(() => {
    if (!!review) setReview(null);
  }, [review, setReview]);

  const hasAccess = (role: Role) => {
    return userProfile?.roles.includes(role);
  };

  return (
    <>
      {!userProfile ? (
        <LoadingModal
          isLoading={!userProfile}
          loadingType={LoadingType.General}
        />
      ) : (
        <>
          <Grid item>
            {hasAccess(Role.TITLEPAGEUSER) ? (
              <Button
                component={RouterLink}
                to='/order'
                className={classes.mainButton}
                variant='contained'
                fullWidth
                color='primary'>
                Beställ katalogisering
              </Button>
            ) : (
              <Button
                className={classes.noAccessButton}
                variant='contained'
                fullWidth
                color='primary'
                onClick={() =>
                  window.open(
                    'https://www.btj.se/bestall-katalogisering_pre',
                    '_self'
                  )
                }>
                Beställ katalogisering
              </Button>
            )}
          </Grid>
          <Grid item>
            {hasAccess(Role.REVIEWBANKUSER) ? (
              <Button
                style={{ marginBottom: '2rem' }}
                component={RouterLink}
                to='/bookreview'
                className={classes.mainButton}
                variant='contained'
                fullWidth
                color='primary'>
                Recensionsbanken
              </Button>
            ) : (
              <Button
                className={classes.noAccessButton}
                variant='contained'
                fullWidth
                color='primary'
                onClick={() =>
                  window.open(
                    'https://www.btj.se/recensionsbanken_pre',
                    '_self'
                  )
                }>
                Recensionsbanken
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button
              className={classes.subButtons}
              component={RouterLink}
              to='/account'
              fullWidth>
              Mitt bibliotek
            </Button>
          </Grid>
          <Grid item xs-={3}>
            <Button
              className={classes.logoutButton}
              variant={'text'}
              color={'secondary'}
              fullWidth
              onClick={() => {
                auth.userManager.clearStaleState();
                auth.signOutRedirect();
              }}>
              Logga ut
            </Button>
          </Grid>
        </>
      )}
    </>
  );
}

export default Dashboard;

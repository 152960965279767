//@ts-nocheck
export const invalidBarCode =
  'Det angivna numret är inget giltigt ISBN. Försök igen eller skriv in ISBN manuellt.';
const invalidISBN = 'Felaktigt ISBN';
const invalidCharacter = 'Felaktigt tecken';
const invalidISBNLength = `Du måste ange ett giltigt ISBN eller markera att ISBN saknas`;
const invalidISBNLengthBookReview = `Du måste ange ett giltigt ISBN`;
const invalidEANLength = `Du måste ange ett giltigt ISBN eller EAN eller markera att ISBN eller EAN saknas`;
const invalidEAN = 'Felaktigt ISBN eller EAN';

export function isBarCodeValid(barCode: string) {
  return (
    barCode.includes('OCR') ||
    barCode.substring(0, 3) === '978' ||
    barCode.substring(0, 3) === '979'
  );
}

export function validateISBN(
  isbn: string | null | undefined,
  isISBN: boolean,
  isOrderFlow?: boolean = true
) {
  let error: string | undefined = undefined;

  if (!isbn || isbn?.length === 0) {
    error = invalidLenghtMessage(isISBN, isOrderFlow);
  } else if (areCharacterInvalid(isbn)) {
    error = invalidCharacter;
  } else if (!isISBNValid(isbn)) {
    error = invalidCodeMessage(isISBN);
  }

  return error;
}

function invalidLenghtMessage(isISBN, isOrderFlow = true) {
  return isISBN
    ? isOrderFlow
      ? invalidISBNLength
      : invalidISBNLengthBookReview
    : invalidEANLength;
}

function invalidCodeMessage(isISBN) {
  return isISBN ? invalidISBN : invalidEAN;
}

function areCharacterInvalid(value) {
  return value.replace(/(\d|-)+|(X|x|)?$/g, '').length > 0;
}

function isISBNValid(isbn) {
  if (isbn === '') return true;

  isbn = clean_isbn(isbn);

  return check_10(isbn) || check_13(isbn) || check_13(`0${isbn}`);
}

function clean_isbn(isbn) {
  ///NOTE: The checksum value for ISBN 10 can be an X (repersenting the number 10).
  return String(isbn).replace(/-/g, '');
}

function calculate_check_10(isbn) {
  let tmp = 0;

  for (let i = 0; i < 9; i++) {
    tmp += isbn[i] * (10 - i);
  }

  tmp = 11 - (tmp % 11);

  if (tmp === 10) {
    tmp = 'X';
  }

  if (tmp === 11) {
    tmp = 0;
  }

  return tmp;
}

function calculate_check_13(isbn) {
  let tmp = 0;
  for (let i = 0; i < 12; i++) {
    tmp += Number(isbn[i]) * (i % 2 ? 3 : 1);
  }

  return (10 - (tmp % 10)) % 10;
}

function check_10(isbn) {
  isbn = isbn.toUpperCase();
  if (isbn.length !== 10) {
    return false;
  }

  ///NOTE: Since the checksum can be "X", we need to make sure it's a string.
  return String(calculate_check_10(isbn.substring(0, 9))) === isbn[9];
}

function check_13(isbn) {
  if (isbn.length !== 13) {
    return false;
  }

  return calculate_check_13(isbn.substring(0, 12)) === Number(isbn[12]);
}

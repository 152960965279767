import Quota from '../../types/QuotaInfo.type';

async function getQuotaInfoFromApi(): Promise<Quota | undefined> {
  const res = await fetch('api/v1/titlepage/catalogingorder/QoutaInfo', {
    method: 'GET',
  });

  if (res.status === 200) {
    return res.json();
  }
}

export default getQuotaInfoFromApi;

import React, { useEffect } from 'react';
import {
  Button,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import {
  ArrowBack,
  LibraryMusic,
  MenuBook,
  OndemandVideo,
  SettingsEthernet,
  SportsEsports,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import FormHeader from '../components/FormHeader';
import FormPage from '../components/FormPage';
import textContent from './../assets/textContent.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    productButton: {
      maxHeight: '12vh',
      height: '100%',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    ordersButton: {
      maxHeight: '12vh',
      height: '100%',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      background: '#00635e',
      color: '#ffff',
      fontWeight: 'bold',
    },
    formPaper: {
      border: 'none',
      minHeight: '100vh',
      paddingTop: theme.spacing(12),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    navPaper: {
      width: '100%',
      border: 'none',
    },
    marginTop: {
      marginTop: '1.5rem',
    },
  })
);

function NewOrder() {
  const classes = useStyles();
  const {
    pageHeader,
    pageInstruction,
    helperHeader,
    helperItems,
  } = textContent.newOrder;

  useEffect(() => {
    function disableNav() {
      window.history.pushState(null, document.title, window.location.href);
    }

    disableNav();
    window.addEventListener('popstate', disableNav);

    return () => {
      window.removeEventListener('popstate', disableNav);
    };
  }, []);

  return (
    <Container maxWidth={'sm'}>
      <Paper className={classes.formPaper}>
        <FormHeader textContent={'NY BESTÄLLNING'} />
        <FormPage
          header={pageHeader}
          instruction={pageInstruction}
          helperHeader={helperHeader}
          helperItems={helperItems}>
          <Grid className={classes.root} container spacing={3}>
            <Grid item xs={12}>
              <Button
                component={Link}
                to='book/isbn'
                className={classes.productButton}
                fullWidth
                startIcon={<MenuBook />}>
                Bok
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                component={Link}
                to='game/content'
                className={classes.productButton}
                fullWidth
                startIcon={<SportsEsports />}>
                Tv-spel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                component={Link}
                to='music/content'
                className={classes.productButton}
                fullWidth
                startIcon={<LibraryMusic />}>
                Musik
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                component={Link}
                to='movie/content'
                className={classes.productButton}
                fullWidth
                startIcon={<OndemandVideo />}>
                Film
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                component={Link}
                to='misc/content'
                className={classes.productButton}
                fullWidth={true}
                startIcon={<SettingsEthernet />}>
                Övrigt
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.marginTop}>
              <Button
                className={classes.ordersButton}
                variant='contained'
                fullWidth
                color='primary'
                component={Link}
                to='history'>
                Mina beställningar
              </Button>
            </Grid>
          </Grid>
          <Paper className={classes.navPaper}>
            <Grid
              container
              className={classes.root}
              direction='row'
              justify='space-evenly'
              spacing={1}>
              <Grid item xs={4}>
                <Button component={Link} to='/' startIcon={<ArrowBack />}>
                  Tillbaka
                </Button>
              </Grid>
              <Grid item xs={8}></Grid>
            </Grid>
          </Paper>
        </FormPage>
      </Paper>
    </Container>
  );
}

export default NewOrder;

import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormHeader from '../../components/FormHeader';
import { Container, Grid, Divider } from '@material-ui/core';

import BookReviewSummary from './BookReviewSummary/BookReviewSummary';
import BookReviewDetails from './BookReviewDetails/BookReviewDetails';
import BtjContext from '../../context/BtjContext';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(11),
    backgroundColor: '#fff',
    height: '100%',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    display: 'flex',
    flexWrap: 'wrap',
  },
  body: { flex: '1', padding: '0 1rem', background: '#fff' },
  divider: { width: '70%', margin: '1rem auto' },
}));

function BookReview() {
  const { review, setReview } = useContext(BtjContext);
  const [tabIndex, setTabIndex] = useState(0);
  const classes = useStyles();
  const navigate = useNavigate();
  useEffect(() => {
    if (!review) {
      navigate(-1);
    }

    return () => {
      setReview(null);
    };
  }, []);

  useEffect(() => {
    setTabIndex(0);

    const el = document.getElementById('app');

    el &&
      el.scroll({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
  }, [review]);

  const calcSeriesIndex = () => {
    let seriesIndex = 1;
    if (review && review.contentDescriptions.length > 0) seriesIndex++;
    if (review && review.publisherTexts.length > 0) seriesIndex++;
    if (review && review.series) seriesIndex++;
    return seriesIndex;
  };

  return (
    <Container maxWidth={'sm'} className={classes.root}>
      {!!review && (
        <>
          <FormHeader textContent='RECENSIONSBANKEN' showCancel={true} />
          <Grid className={classes.body} container direction='column'>
            <BookReviewSummary
              {...review}
              handleTabIndex={() => {
                setTabIndex(calcSeriesIndex());
              }}
            />
            <Divider className={classes.divider} />
            <BookReviewDetails
              {...review}
              tabIndex={tabIndex}
              handleTabIndex={setTabIndex}
            />
          </Grid>
        </>
      )}
    </Container>
  );
}
export default BookReview;

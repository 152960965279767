import React from 'react';
import {
  Accordion,
  AccordionSummary,
  createStyles,
  makeStyles,
  Paper,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import FormValues from '../types/FormValues.type';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imagePreviewImage: {
      objectFit: 'cover',
      maxWidth: '100%',
      borderRadius: '0.5rem',
      borderStyle: 'solid',
      borderColor: theme.palette.action.hover,
    },
    paper: {
      border: 'none',
      padding: theme.spacing(4),
    },
  }),
);

type Props = {
  images: FormValues['images'];
};

function ResultsTableImages({ images = [] }: Props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panelId: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panelId : false);
  };
  return (
    <>
      <TableRow>
        <TableCell>
          {images.map(
            (image, index) =>
              image.file && (
                <Accordion
                  key={`resultsImage-${index}`}
                  expanded={expanded === `resultsImage-${index}`}
                  onChange={handleChange(`resultsImage-${index}`)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`Utfällbar förhandsvisning`}
                    id='panel1a-header'>
                    <Typography variant={'subtitle1'} component={'h3'}>
                      {image.description.substring(0, 30)}
                    </Typography>
                  </AccordionSummary>
                  <Paper className={classes.paper}>
                    <img
                      className={classes.imagePreviewImage}
                      alt={image.description}
                      src={image.file ? URL.createObjectURL(image.file) : ''}
                    />
                  </Paper>
                </Accordion>
              ),
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

export default ResultsTableImages;

import { useState, useContext } from 'react';

import {
  makeStyles,
  createStyles,
  Button,
  Modal,
  Grid,
  Theme,
  Typography,
  IconButton,
} from '@material-ui/core';

import BarCodeReader from './BarCodeReader';
import OCRReader from './OCRReader';

import barCodeIcon from '../assets/barcode-icon.svg';
import ocrIcon from '../assets/ocr-icon.svg';

import classNames from 'classnames';
import BtjContext from '../context/BtjContext';

type Props = {
  open: boolean;
  onClose: Function;
  onCodeRead: Function;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: { overflow: 'auto' },
    modalContent: {
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '.5rem 0',
      '@media (max-width:320px)': {
        padding: 0,
      },
    },
    root: {
      minHeight: '96vh',
      position: 'relative',
      border: '1px solid transparent',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      background: 'white',
      padding: '0 1rem .5rem 1rem',
      borderRadius: '6px',
    },
    closeModal: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 'bold',
      width: '100%',
      border: '1px solid #a9a9a9',
      height: '2.8rem',
    },
    scannerOption: {
      flex: '0 48%',
      display: 'flex',
      flexDirection: 'column',
      '& p': {
        marginBottom: '0.5rem',
      },
    },
    scanner: {
      padding: '0.6rem 1rem',
      borderRadius: '6px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: '3.5rem',
      width: '100%',
      border: 'none',
      backgroundColor: '#ddebdf',
      backgroundPosition: 'center',
      opacity: 0.7,
      '&:hover': {
        backgroundColor: '#ddebdf',
      },
    },
    selectedScanner: {
      border: '2px solid #00635e',
      opacity: 1,
    },
    codeReader: { width: '320px', paddingBottom: '.5rem' },
    hurVillDu: { flex: '0 100%', fontWeight: 'bold' },
  })
);

function CodeReaderModal({ open, onClose, onCodeRead }: Props) {
  const [isBarcodeReader, setIsBarcodeReader] = useState(true);
  const classes = useStyles();
  const { userProfile } = useContext(BtjContext);

  const handleCode = (result: string | null) => {
    //sets code
    if (!isBarcodeReader && !!result) {
      result = String(result)?.concat('OCR');
    }

    onCodeRead(result);
    onClose(false);
  };

  return (
    <Modal className={classes.modal} open={open}>
      <Grid className={classes.modalContent}>
        <Grid className={classes.root}>
          <Grid item>
            <Grid item>
              {isBarcodeReader ? (
                <BarCodeReader userProfile={userProfile} handleBarCode={handleCode} />
              ) : (
                <OCRReader handleOCRCode={handleCode} />
              )}
            </Grid>
            <Grid
              item
              container
              justify='space-between'
              className={classes.codeReader}>
              <Typography
                className={classes.hurVillDu}
                variant={'h4'}
                component={'h4'}
                paragraph>
                Hur vill du skanna ISBN/EAN?
              </Typography>
              <Grid className={classes.scannerOption}>
                <Typography variant={'h5'} component={'p'}>
                  ISBN/EAN som streckkod
                </Typography>
                <IconButton
                  className={classNames(
                    classes.scanner,
                    isBarcodeReader && classes.selectedScanner
                  )}
                  onClick={() => {
                    setIsBarcodeReader(true);
                  }}>
                  <img src={barCodeIcon} alt='barcode' />
                </IconButton>
              </Grid>
              <Grid className={classes.scannerOption}>
                <Typography variant={'h5'} component={'p'}>
                  ISBN/EAN som siffror
                </Typography>
                <IconButton
                  className={classNames(
                    classes.scanner,
                    !isBarcodeReader && classes.selectedScanner
                  )}
                  aria-label='ocr'
                  onClick={() => {
                    setIsBarcodeReader(false);
                  }}>
                  <img src={ocrIcon} alt='ocr' />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Button
            className={classes.closeModal}
            component='button'
            onClick={() => {
              handleCode(null);
            }}>
            Stäng
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default CodeReaderModal;

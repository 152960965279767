import { Language } from './Language.type';

export enum MediaType {
  Book = 1,
  VideoGame = 2,
  Film = 3,
  Music = 4,
  Misc = 99,
}

export type ImageFileViewModel = {
  file: File | null;
  description: string;
  imageFileId?: string;
};

type FormValues = {
  mediaType: MediaType;
  isbn?: string | null;
  noIsbn?: boolean | null;
  languages?: Array<Language>;
  labels: boolean;
  unknownLanguage: boolean | null;
  parallelText: boolean;
  tableOfContents?: boolean | null;
  paginationUnknown?: boolean;
  paginationMissing?: boolean;
  numberOfPages?: string | null;
  spiralBinding?: boolean | null;
  images: Array<ImageFileViewModel>;
  hasAppendixes?: boolean | null;
  appendixes: Array<ImageFileViewModel>;
  hasIllustrations?: boolean | null;
  illustrations: Array<ImageFileViewModel>;
  numberOfLabels?: number | null;
  notes?: string;
  isMiscPage?: boolean;
};
export default FormValues;

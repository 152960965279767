import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import Role from '../auth/Role';
import BtjContext from '../context/BtjContext';
import LoadingModal from './LoadingModal';
import LoadingType from '../types/LoadingType';

const PrivateRoute = ({
  children,
  roles,
}: {
  children: JSX.Element;
  roles: Array<Role>;
}) => {
  const { userData } = useAuth();
  const { userProfile, loading } = useContext(BtjContext);
  if (loading === null || loading)
    return <LoadingModal isLoading={true} loadingType={LoadingType.General} />;

  return !userData ||
    userProfile?.roles.findIndex((r) => roles.includes(r)) === -1 ? (
    <Navigate to='/' replace />
  ) : (
    children
  );
};

export default PrivateRoute;

import React, { ChangeEvent, useEffect } from 'react';
import IsbnField from '../../../components/IsbnField';
import FormPage from '../../../components/FormPage';
import FormPageProps from '../../../types/FormPageProps.type';

function IsbnForm({
  values,
  validate,
  touched,
  errors,
  onChange,
  onBlur,
  setFieldError,
  setFieldValue,
  setActiveFields,
  setFieldTouched,
  textContent,
}: FormPageProps) {
  useEffect(() => {
    setActiveFields(['isbn']);
  }, [setActiveFields]);
  const {
    pageHeader,
    pageInstruction,
    helperHeader,
    helperItems,
  } = textContent.isbnForm;
  function handleNoIsbn(event: ChangeEvent<HTMLInputElement>) {
    const noIsbn = event.target.checked;
    setFieldValue('noIsbn', noIsbn);
    if (noIsbn) {
      setFieldValue('isbn', null);
    } else {
      setFieldValue('isbn', '');
    }
    validate(['isbn']);
  }

  return (
    <FormPage
      header={pageHeader}
      instruction={pageInstruction}
      helperHeader={helperHeader}
      helperItems={helperItems}>
      <IsbnField
        onChange={onChange}
        error={errors.isbn}
        isbnValue={values.isbn}
        noIsbnValue={values.noIsbn}
        touched={touched.isbn}
        handleBlur={onBlur}
        handleNoIsbn={handleNoIsbn}
        setFieldValue={setFieldValue}
        setFieldError={setFieldError}
        setFieldTouched={setFieldTouched}
      />
    </FormPage>
  );
}

export default IsbnForm;

const ENDPOINT = '/api/v1/titlepage/ImageFile';

export async function postImage(file: File): Promise<string | null> {
  const formData = new FormData();
  formData.set('formFile', file);

  try {
    const response = await fetch(ENDPOINT, {
      method: 'POST',
      body: formData,
      headers: {
        accept: 'text/plain',
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.id;
    }

    return null;
  } catch (err) {
    return null;
  }
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import OngoingOrders from './BookOrder/OrderList/OngoingOrders';
import CompletedOrders from './BookOrder/OrderList/CompletedOrders';
import FormHeader from '../components/FormHeader';
import { Button, Container, Grid, Slide } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import ScrollToTopFab from '../components/ScrollToTopFab';
import HelpButton from '../components/HelpButton';
import textContent from './../assets/textContent.json';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(12),
  },
  formPaper: {
    border: 'none',
    minHeight: '100vh',
    paddingTop: theme.spacing(12),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  navPaper: {
    width: '100%',
    border: 'none',
    paddingTop: theme.spacing(12),
  },
  info: {
    position: 'absolute',
    right: '0',
    top: '0',
  },
}));

function OrderHistory() {
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { helperHeader, helperItems } = textContent.orderHistory;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Slide direction={'left'} in={true}>
      <Container maxWidth={'sm'} className={classes.root}>
        <ScrollToTopFab />
        <Grid container spacing={2} direction={'column'}>
          <Grid style={{ position: 'relative' }} item>
            <FormHeader textContent={'MINA BESTÄLLNINGAR'} />
            <Button onClick={() => navigate(-1)} startIcon={<ArrowBack />}>
              Tillbaka
            </Button>
            <div className={classes.info}>
              <HelpButton helperItems={helperItems} header={helperHeader} />
            </div>
          </Grid>
          <Grid item>
            <AppBar position='static' color='default'>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor='primary'
                textColor='primary'
                variant='fullWidth'
                aria-label='full width tabs example'>
                <Tab label='Pågående' {...a11yProps(0)} />
                <Tab label='Slutförda' {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <OngoingOrders />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <CompletedOrders />
            </TabPanel>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            direction={'row'}
            justify={'space-between'}>
            <Grid item>
              <Button onClick={() => navigate(-1)} startIcon={<ArrowBack />}>
                Tillbaka
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Slide>
  );
}

export default OrderHistory;

import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import UserInfo from './UserInfo';
import BtjContext from '../../context/BtjContext';
import UsageInformation from './UsageInformation';
import Role from '../../auth/Role';

function LibraryInfo() {
  const { userProfile } = useContext(BtjContext);

  const hasPermission = () => {
    return userProfile?.roles.includes(Role.TITLEPAGEUSER);
  };

  return (
    <Grid container spacing={1} direction={'column'}>
      <Grid item>
        <UserInfo />
      </Grid>
      {hasPermission() && <UsageInformation />}
    </Grid>
  );
}

export default LibraryInfo;

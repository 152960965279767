import React, { useContext } from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import RateReviewIcon from '@material-ui/icons/RateReview';
import StarsIcon from '@material-ui/icons/Stars';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import Carousel from 'react-material-ui-carousel';

import SpecialistReview from '../SpecialistReview/SpecialistReview';
import SpecialistGrade from '../SpecialistGrade/SpecialistGrade';
import BookDescription from '../BookDescription/BookDescription';
import BookSeries from '../BookSeries/BookSeries';
import { Review, Series } from '../../../types/BookReview';
import GAEvent from '../../../helpers/GAEvent';
import BtjContext from '../../../context/BtjContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: { flex: 1, flexWrap: 'wrap' },
  carousel: {
    flex: 'auto',
    '& .CarouselItem': {
      height: '100% !important',
    },
  },
  carouselIndicator: {
    position: 'fixed',
    bottom: '3.2rem',
    left: '0',
    margin: 0,
    zIndex: 1000,
    background: '#fff',
    borderTop: '1px solid rgba(0,0,0,0.14)',
  },
  carouselNavButtons: {
    backgroundColor: 'transparent',
    borderRadius: 0,
    color: 'rgba(0, 0, 0, 0.5)',
    padding: '0',
    margin: '0',
  },
  carouselDot: {
    color: '#00635e',
  },
  bottomNavRoot: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    border: 'none',
    padding: '0 .25rem',
    boxSizing: 'border-box',
    '& button': {
      minWidth: '60px',
      '& span span': {
        fontSize: '.8rem !important',
      },
    },
  },
}));

type BookReviewDetailsProps = {
  contentDescriptions: Array<Review>;
  firstReaderReviews: Array<Review>;
  editorialTexts: Array<Review>;
  publisherTexts: Array<Review>;
  series?: Series | null;
  tabIndex: number;
  handleTabIndex: Function;
};

function BookReviewDetails({
  contentDescriptions,
  firstReaderReviews,
  editorialTexts,
  publisherTexts,
  series,
  tabIndex,
  handleTabIndex,
}: BookReviewDetailsProps) {
  const classes = useStyles();

  const { userProfile } = useContext(BtjContext);
  const registerGAEvent = (actionDetail:string) => {
    GAEvent('View book detail', {
      customerName: userProfile ? userProfile.customerName : 'anonymous',
      appenService: 'Recensionsbanken',
      actionDetail,
    });
  }

  const handleNavChange = (event: any, newValue: any) => {
    handleTabIndex(newValue);
  };

  const mapCarouselItems = () => {
    let indexCount = 0;
    const items = [];
    items.push(
      <SpecialistReview
        key={indexCount}
        isMainReview={true}
        reviews={[...firstReaderReviews, ...editorialTexts]}
      />
    );
    indexCount++;
    items.push(
      <SpecialistGrade key={indexCount} reviews={firstReaderReviews} />
    );

    if (contentDescriptions.length > 0) {
      indexCount++;
      items.push(
        <BookDescription
          key={indexCount}
          contentDescriptions={contentDescriptions}
        />
      );
    }

    if (publisherTexts.length > 0) {
      indexCount++;
      items.push(
        <SpecialistReview
          key={indexCount}
          isMainReview={false}
          reviews={publisherTexts}
        />
      );
    }

    if (!!series) {
      indexCount++;
      items.push(<BookSeries key={indexCount} series={series} />);
    }

    return items;
  };

  const mapNavButtons = () => {
    let indexCount = 0;
    const items = [];
    items.push(
      <BottomNavigationAction
        showLabel={true}
        key={indexCount}
        label={'Lektörsomdöme'}
        value={indexCount}
        icon={<RateReviewIcon />}
      />
    );
    indexCount++;
    items.push(
      <BottomNavigationAction
        showLabel={true}
        key={indexCount}
        label={'Helhetsbetyg'}
        value={indexCount}
        icon={<StarsIcon />}
      />
    );

    if (contentDescriptions.length > 0) {
      indexCount++;
      items.push(
        <BottomNavigationAction
          showLabel={true}
          key={indexCount}
          label={'Beskrivning'}
          value={indexCount}
          icon={<DescriptionIcon />}
        />
      );
    }

    if (publisherTexts.length > 0) {
      indexCount++;
      items.push(
        <BottomNavigationAction
          showLabel={true}
          key={indexCount}
          label={'Förlagstext'}
          value={indexCount}
          icon={<SpeakerNotesIcon />}
        />
      );
    }

    if (!!series) {
      indexCount++;
      items.push(
        <BottomNavigationAction
          showLabel={true}
          key={indexCount}
          label={'Serien'}
          value={indexCount}
          icon={<CollectionsBookmarkIcon />}
        />
      );
    }

    return items;
  };

  return (
    <Grid container className={classes.root}>
      <Carousel
        className={classes.carousel}
        indicatorContainerProps={{ className: classes.carouselIndicator }}
        activeIndicatorIconButtonProps={{
          className: classes.carouselDot,
        }}
        navButtonsProps={{ className: classes.carouselNavButtons }}
        autoPlay={false}
        animation='slide'
        navButtonsAlwaysVisible={false}
        swipe={true}
        indicators={true}
        cycleNavigation={false}
        index={tabIndex}
        onChange={(now: number, prev: number) => {
          const buttons = mapNavButtons().map(item => item.props.label);
          if (prev !== now) {
            handleTabIndex((value: number) => {
              if (prev === value && prev !== now) {
                registerGAEvent(buttons[now]);
                return now;
              }

              registerGAEvent(buttons[value]);
              return value;
            });
          }
        }}>
        {mapCarouselItems()}
      </Carousel>
      <BottomNavigation
        className={classes.bottomNavRoot}
        value={tabIndex}
        onChange={handleNavChange}>
        {mapNavButtons()}
      </BottomNavigation>
    </Grid>
  );
}

export default BookReviewDetails;

import Cookies from 'universal-cookie';

export function setCookieConsent(key: string, value: string) {
  const cookies = new Cookies();
  const oneYearFromNow = new Date();
  oneYearFromNow.setMonth(new Date().getMonth() + 12);
  cookies.set(key, value, {
    expires: oneYearFromNow,
    path: '/',
    sameSite: 'none',
    secure: true,
  });
}

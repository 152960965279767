import React from 'react';
import { TableCell, Typography } from '@material-ui/core';
import FormValues from '../types/FormValues.type';
import ShadedTableRow from './ShadedTableRow';

type Props = {
  tableOfContents: FormValues['tableOfContents'];
  numberOfPages: FormValues['numberOfPages'];
  hasIllustrations: FormValues['hasIllustrations'];
  spiralbinding: FormValues['spiralBinding'];
};

function ResultsTableContents({ tableOfContents, numberOfPages, spiralbinding, hasIllustrations }: Props) {
  return (
    <>
      <ShadedTableRow>
        <TableCell>
          <Typography>Innehållsförteckning</Typography>
        </TableCell>
        <TableCell>
          {tableOfContents ? (
            <Typography>Ja</Typography>
          ) : (
            <Typography>Nej</Typography>
          )}
        </TableCell>
      </ShadedTableRow>
      <ShadedTableRow>
        <TableCell>
          <Typography>Antal sidor</Typography>
        </TableCell>
        <TableCell>{numberOfPages}</TableCell>
      </ShadedTableRow>

        <ShadedTableRow>
        <TableCell>
          <Typography>Spiralbunden</Typography>
        </TableCell>
        <TableCell> {spiralbinding ? (
          <Typography>Ja</Typography>
        ) : (
          <Typography>Nej</Typography>
        )}</TableCell>
      </ShadedTableRow>
        <ShadedTableRow>
        <TableCell>
          <Typography>Har illustrationer?</Typography>
        </TableCell>
        <TableCell> {hasIllustrations ? (
          <Typography>Ja</Typography>
        ) : (
          <Typography>Nej</Typography>
        )}</TableCell>
      </ShadedTableRow>

    </>
  );
}

export default ResultsTableContents;

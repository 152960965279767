import React, { useContext, useState } from 'react';
import { Grid } from '@material-ui/core';
import { ImageFileViewModel } from '../types/FormValues.type';
import PhotoDrawer from './PhotoDrawer';
import BtjContext from '../context/BtjContext';

type Props = {
  commentSection?: boolean;
  errors?: any;
  handleImageChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    setPromiseList: Function
  ) => void;
  handleDescriptionChange?: (value: string, index: number) => void;
  images: Array<ImageFileViewModel>;
};

function PhotoAccordion({
  commentSection = false,
  errors,
  handleImageChange,
  handleDescriptionChange,
  images = [],
}: Props) {
  const { setPromiseList } = useContext(BtjContext);
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleAccordionChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };
  const accordionKey = (index: number | string) => `photoDrawer-${index}`;

  return (
    <Grid container direction={'column'} wrap={'nowrap'}>
      {images.map((image: ImageFileViewModel, index: number) => (
        <Grid item key={accordionKey(index)}>
          <PhotoDrawer
            expanded={expanded === accordionKey(index)}
            handleExpansion={handleAccordionChange(accordionKey(index))}
            image={image}
            error={!!errors && errors[index]}
            handleImageChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleImageChange(event, index, setPromiseList)
            }
            handleDescriptionChange={(value: string) =>
              handleDescriptionChange && handleDescriptionChange(value, index)
            }
            commentSection={commentSection}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default PhotoAccordion;

import { Language } from '../types/Language.type';

export default function validateLanguages(
  languages: Array<Language> | undefined,
  parallelText: boolean,
  unknownLanguage: boolean | null,
  setFieldError: (fieldName: string, errorMessage: string | undefined) => void,
): string | undefined {

  let result: string | undefined = undefined;

  if (unknownLanguage === null) {
    result = 'Du måste välja något alternativ.';
    setFieldError('unknownLanguage', result);
  } else if (unknownLanguage) {
    result = undefined;
    setFieldError('languages', result);
    setFieldError('unknownLanguage', result);
  } else if (!unknownLanguage) {
    setFieldError('unknownLanguage', undefined);
    if (!languages) {
      result = 'Du måste ange språk.';
      setFieldError('languages', result);
    } else if (languages && languages.length === 0) {
      result = 'Du måste ange språk.';
      setFieldError('languages', result);
    } else if (parallelText && languages && languages.length === 1) {
      result = 'Ange minst två språk för verk med parallelltext';
      setFieldError('languages', result);
    }
  } else {
    setFieldError('languages', undefined);
    setFieldError('unknownLanguage', undefined);
  }
  return result;
}

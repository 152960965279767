import React from 'react';
import { Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Link,
  Typography
   } from '@material-ui/core';

type Props = {
  title: string;
  information: string;
  link: string;
  handleConfirm: () => void;
};

function InfoPopup({ title, information, link, handleConfirm }: Props) {
  return (
    <Dialog open={true} onClose={handleConfirm}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
      <Typography
        component='p'
        align='center'
        paragraph>
        <Link href={link}>
          {information}
        </Link>
      </Typography>
      </DialogContent>
      <DialogActions>
      <Grid
        container
        direction='column'
        alignItems='center'>
        <Button
          color='primary'
          variant='contained'
          onClick={handleConfirm}
          style={{justifyContent: 'center'}}
        >
          STÄNG
        </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default InfoPopup;

import React, { useContext } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableHead,
} from '@material-ui/core';
import BtjContext from '../../context/BtjContext';
import InfoIcon from '@material-ui/icons/Info';
import PriceInfo from '../../types/PriceInfo.type';
import ShadedTableRow from '../../components/ShadedTableRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: '.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    root: {
      padding: '1rem  0 !important',
    },
    span: {
      flex: 1,
      lineHeight: '1.25rem',
      textAlign: 'right',
      padding: '0.15rem',
      '@media (max-width: 375px)': {
        lineHeight: '1rem',
        fontSize: '0.775rem',
      },
      '@media (max-width: 320px)': {
        lineHeight: '.8rem',
        fontSize: '0.675rem',
      },
    },
    table: {
      borderStyle: 'none',
      marginBottom: '1rem',
    },
    tableCell: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: 'none',
    },
    description: {
      lineHeight: '1.25rem',
      '@media (max-width: 375px)': {
        lineHeight: '1rem',
        fontSize: '0.775rem',
      },
      '@media (max-width: 320px)': {
        lineHeight: '.8rem',
        fontSize: '0.675rem',
      },
    },
  })
);

type PricesInfoProps = {
  prices: Array<PriceInfo>;
  showQuota: boolean;
};

const headerRow = [
  'Utan Bibliografisk service',
  'Med Bibliografisk service',
  'Ditt pris',
];

function PricesInfo({ prices, showQuota }: PricesInfoProps) {
  const { setModalTab } = useContext(BtjContext);
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root} item>
        <Grid style={{ flex: 1 }} item>
          <Typography className={classes.title} component={'h2'} variant={'h3'}>
            Styckpris för beställningar om överskriden fri katalogiseringskvot
            <IconButton
              color={'primary'}
              onClick={() => {
                setModalTab(
                  'Styckpris för beställningar om överskriden fri katalogiseringskvot'
                );
              }}>
              <InfoIcon />
            </IconButton>
          </Typography>
        </Grid>

        <TableContainer className={classes.table} component={Paper}>
          <Table>
            <TableHead>
              <TableCell className={classes.tableCell}>
                <Grid style={{ flex: '0 40%' }} item></Grid>
                <Grid
                  style={{ flex: '0 60%' }}
                  item
                  container
                  direction='row'
                  justify='space-between'
                  alignContent='center'>
                  {headerRow.map((value, index) => {
                    return (
                      <Typography
                        key={index}
                        className={classes.span}
                        component={'span'}
                        variant={'subtitle2'}>
                        {value === 'Ditt pris' ? <b>{value}</b> : value}
                      </Typography>
                    );
                  })}
                </Grid>
              </TableCell>
            </TableHead>
            <TableBody>
              {prices.map((p, index) => {
                return (
                  <ShadedTableRow key={index}>
                    <TableCell className={classes.tableCell}>
                      <Grid style={{ flex: '0 40%' }} item>
                        <Typography
                          className={classes.description}
                          component={'span'}
                          variant={'subtitle1'}>
                          {p.description}:
                        </Typography>
                      </Grid>
                      <Grid
                        style={{ flex: '0 60%' }}
                        item
                        container
                        direction='row'
                        justify='space-between'
                        alignContent='center'>
                        <Typography
                          className={classes.span}
                          component={'span'}
                          variant={'subtitle2'}>
                          {p.ubsPrice} {p.unit}
                        </Typography>
                        <Typography
                          className={classes.span}
                          component={'span'}
                          variant={'subtitle2'}>
                          {p.bsPrice} {p.unit}
                        </Typography>
                        <Typography
                          className={classes.span}
                          component={'span'}
                          variant={'subtitle2'}>
                          <b>
                            {p.myPrice} {p.unit}
                          </b>
                        </Typography>
                      </Grid>
                    </TableCell>
                  </ShadedTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item>
        <hr />
      </Grid>
    </>
  );
}

export default PricesInfo;

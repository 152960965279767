import React from 'react';
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Modal,
  Theme,
  Typography,
} from '@material-ui/core';
import LoadingType from '../types/LoadingType';
import bg from '../assets/images/homebg.jpg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: -2000,
      height: '100vh',
      width: '100vw',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(4),
      backgroundImage: `url('${bg}')`,
      backgroundPosition: 'right 15% bottom 50%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    loadingSpinner: {
      display: 'block',
      position: 'absolute',
      left: 'calc(50% - 20px)',
      top: '40%',
    },
    loadingHeader: {
      marginTop: '30vh',
      color: '#FFF',
      textShadow: '1px 1px 2px #000',
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

type Props = {
  isLoading: boolean;
  loadingType?: LoadingType;
};

function LoadingModal({ isLoading, loadingType = LoadingType.Order }: Props) {
  const classes = useStyles();

  const LoadingText = () => {
    switch (loadingType) {
      case LoadingType.Order:
        return 'SKICKAR BESTÄLLNING';
      case LoadingType.Review:
        return 'SÖKER ISBN...';
      case LoadingType.General:
        return 'LADDAR...';
    }
  };

  return (
    <Modal open={isLoading}>
      <>
        {loadingType === LoadingType.General ? (
          <div className={classes.root}></div>
        ) : null}
        <div>
          <div className={classes.loadingHeader}>
            <Typography noWrap align={'center'} component={'h1'} variant={'h1'}>
              {LoadingText()}
            </Typography>
          </div>
          <div className={classes.loadingSpinner}>
            <CircularProgress color='secondary' size={40} thickness={4} />
          </div>
        </div>
      </>
    </Modal>
  );
}

export default LoadingModal;

import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  TableCell,
  Typography,
  TableContainer,
  Table,
  TableBody,
} from '@material-ui/core';

import BookIcon from '@material-ui/icons/BookOutlined';
import ShadedTableRow from '../../../components/ShadedTableRow';

import { Series } from '../../../types/BookReview';
import BtjContext from '../../../context/BtjContext';
import { getBookReviewByCode } from '../../../services/BookReview/BookReview.service';
import LoadingModal from '../../../components/LoadingModal';
import classNames from 'classnames';
import LoadingType from '../../../types/LoadingType';
import GAEvent from '../../../helpers/GAEvent';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: 'none',
    padding: 0,
    paddingTop: '.5rem',
    borderRadius: 0,
    paddingBottom: '6rem',
  },
  title: {
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: 'bold',
  },
  seriesTitle: { marginBottom: '1.5rem' },
  table: {
    borderStyle: 'none',
    marginBottom: '1rem',
  },
  tableCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  selectedTableCell: {
    cursor: 'default',
  },
  bookIcon: { color: '#00635e' },
  selectedRow: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  bigSeries: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginTop: '1rem',
  },
}));

type BookSeriesProps = {
  series: Series;
};

function BookSeries({ series }: BookSeriesProps) {
  const { userProfile, setReview } = useContext(BtjContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const el = document.getElementById('app');

    el &&
      el.scroll({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
  }, []);

  const classes = useStyles();

  const registerGAEvent = () => {
    GAEvent('Book search', {
      customerName: userProfile ? userProfile.customerName : 'anonymous',
      appenService: 'Recensionsbanken',
      actionDetail: 'Discover series',
    });
  }

  const getBookById = async (id: number) => {
    setIsLoading(true);

    const result = await getBookReviewByCode(id, false);
    setIsLoading(false);
    setReview(result);
    registerGAEvent();
  };

  return (
    <Paper className={classes.root}>
      <LoadingModal isLoading={isLoading} loadingType={LoadingType.Review} />
      <Grid container direction='column' alignItems='flex-start'>
        <Typography
          className={classes.title}
          variant={'body1'}
          component={'label'}>
          Översikt över serien:
        </Typography>
        <Typography
          variant={'h2'}
          component={'h2'}
          className={classes.seriesTitle}>
          {series.title}
        </Typography>
      </Grid>
      <Grid container direction='column' spacing={2}>
        <TableContainer className={classes.table} component={Paper}>
          <Table>
            <TableBody>
              {series.parts &&
                series.parts.map((part, index) => {
                  return (
                    <ShadedTableRow key={index}>
                      <TableCell
                        onClick={(e) => {
                          !part.selected
                            ? getBookById(part.publicationId)
                            : e.preventDefault();
                        }}
                        className={classNames(
                          classes.tableCell,
                          part.selected ? classes.selectedTableCell : ''
                        )}>
                        <Typography
                          className={part.selected ? classes.selectedRow : ''}>
                          {index + 1}. {part.title}
                        </Typography>
                        {!part.selected && (
                          <BookIcon className={classes.bookIcon} />
                        )}
                      </TableCell>
                    </ShadedTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {series.partsLimit !== 0 && (
        <Grid container direction='column' alignItems='flex-start'>
          <Typography
            className={classes.bigSeries}
            variant={'body1'}
            component={'label'}>
            De {series.partsLimit} första delarna visas.
          </Typography>
        </Grid>
      )}
    </Paper>
  );
}

export default BookSeries;

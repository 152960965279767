import React, {
  ChangeEventHandler,
  FocusEventHandler,
  useEffect,
  useState,
} from 'react';
import {
  createStyles,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      marginBottom: '1rem',
    },
    formControlLabel: {
      margin: 0,
      width: '100%',
      height: '3.75rem',
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      borderStyle: 'solid',
      borderColor: theme.palette.grey['400'],
      borderWidth: '1px',
      borderRadius: theme.shape.borderRadius,
      '&:hover': {
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.btjPink.main,
      },

      label: {
        fontFamily: 'futura pt',
        fontSize: 14,
      },
    },
    checked: {
      color: theme.palette.btjPink.contrastText,
      backgroundColor: theme.palette.btjPink.main,
      borderColor: '#004541',
    },
  })
);

type Props = {
  error?: string;
  text: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  name: string;
  value: any;
  alternatives: { label: string; value: any }[];
  small?: boolean;
  onBlur: FocusEventHandler<HTMLLabelElement>;
  touched: boolean | undefined;
};

const RadioButtons = ({
  error,
  small = false,
  alternatives,
  text,
  onChange,
  name,
  value,
  onBlur,
  touched,
}: Props) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState('');
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <FormControl error={!!error} className={classes.formControl}>
      <RadioGroup value={value} onChange={onChange} name={name}>
        <Grid
          container
          direction={'column'}
          wrap={small ? 'nowrap' : 'wrap'}
          spacing={2}>
          <Grid item>
            <Typography variant={'h3'} component={'h3'}>
              {text}
            </Typography>
          </Grid>
          <Grid item container direction={'row'} spacing={1}>
            {alternatives.map(({ label, value }, index) => (
              <Grid
                item
                xs={small ? 6 : 12}
                md={6}
                key={`radio-button-${index}`}>
                <FormControlLabel
                  className={classes.formControlLabel}
                  classes={{
                    root: selectedValue === value ? classes.checked : '',
                  }}
                  labelPlacement='end'
                  name={name}
                  label={
                    <Typography variant={'subtitle1'} component={'span'}>
                      {label}
                    </Typography>
                  }
                  onBlur={onBlur}
                  value={value}
                  control={<Radio />}
                />
              </Grid>
            ))}
            {touched && (
              <FormHelperText style={{ margin: '0 .875rem' }}>
                {error}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtons;

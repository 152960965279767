import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';

import { Review } from '../../../types/BookReview';
import Sanitized from '../../../components/Sanitized';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    border: 'none',
    borderRadius: 0,
    paddingBottom: '6rem',
    paddingTop: '.5rem',
  },
  title: { marginBottom: '1.5rem' },
  textBox: {
    marginBottom: '1rem',
  },
}));

type SpecialistReviewProps = {
  isMainReview: boolean;
  reviews: Array<Review>;
};

function SpecialistReview({ isMainReview, reviews }: SpecialistReviewProps) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography className={classes.title} variant={'h2'} component={'h2'}>
        {isMainReview ? 'Lektörsomdöme' : 'Förlagstext'}
      </Typography>
      <Grid container direction='column'>
        {reviews.length > 0 ? (
          reviews.map(({ author, text }, index) => {
            return (
              <Grid className={classes.textBox} key={index}>
                <Typography variant={'body2'} component={'p'} gutterBottom>
                  <Sanitized html={text} />
                  {isMainReview && <b>&nbsp;{author ?? 'Red.'}</b>}
                </Typography>
              </Grid>
            );
          })
        ) : (
          <Typography variant={'body2'} component={'h3'}>
            Den här titeln är inte recenserad av BTJ
          </Typography>
        )}
      </Grid>
    </Paper>
  );
}

export default SpecialistReview;

import React, { useState } from 'react';
import {
  AppBar,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ArrowBack, Cancel } from '@material-ui/icons';
import AbortOrderDialogue from './AbortOrderDialogue';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.btjPink.main,
      border: 'none',
      color: theme.palette.btjPink.contrastText,
    },
    cancelButton: {
      marginLeft: 0 - theme.spacing(2),
      paddingTop: 0,
      paddingBottom: 0,
      cursor: 'pointer',
      '&:hover': {
        background: 'none',
        border: 'nones',
      },
    },
    buttonContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconText: {
      fontFamily: 'futura-pt',
      fontSize: '12px',
      fontWeight: 800,
      lineHeight: '12px',
      letterSpacing: '0.4px',
    },
  })
);

interface Props {
  textContent: any;
  resetForm?: () => void;
  showCancel?: boolean;
}

function handleAbort(resetForm: () => void, navigate: () => void) {
  resetForm();
  navigate();
}

function FormHeader({ textContent, resetForm, showCancel }: Props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [abortDialogueIsOpen, setAbortDialogueIsOpen] = useState<boolean>(
    false
  );
  //  useEffect(() => {
  //    window.addEventListener('beforeunload', preventUnload);
  //    return () => window.removeEventListener('beforeunload', preventUnload);
  //  }, []);
  return (
    <AppBar className={classes.root} position='fixed'>
      <Toolbar>
        <Grid container direction={'row'} alignItems={'center'}>
          <Grid item xs={2}>
            {(resetForm && (
              <IconButton
                onClick={() => setAbortDialogueIsOpen(true)}
                className={classes.cancelButton}
                color='secondary'
                aria-label='cancel'>
                <div className={classes.buttonContent}>
                  <Cancel />
                  <label className={classes.iconText}>Avbryt beställning</label>
                </div>
              </IconButton>
            )) ||
              (showCancel && (
                <IconButton
                  onClick={() => navigate(-1)}
                  className={classes.cancelButton}
                  color='primary'
                  aria-label='cancel'>
                  <div className={classes.buttonContent}>
                    <ArrowBack />
                    <label className={classes.iconText}>Tillbaka</label>
                  </div>
                </IconButton>
              ))}
          </Grid>
          <Grid item xs={8}>
            <Typography variant={'subtitle2'} align={'center'} noWrap={true}>
              {textContent}
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Toolbar>
      {resetForm && (
        <AbortOrderDialogue
          open={abortDialogueIsOpen}
          handleAbort={() => setAbortDialogueIsOpen(false)}
          handleConfirm={() =>
            handleAbort(resetForm, () => navigate('/order', { replace: true }))
          }
        />
      )}
    </AppBar>
  );
}

export default FormHeader;

import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';

type Props = {
  open: boolean;
  handleConfirm: () => void;
  handleAbort: () => void;
};

function AbortOrderDialogue({ open, handleAbort, handleConfirm }: Props) {
  return (
    <Dialog
      open={open}
      onClose={handleAbort}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle>{'Vill du radera denna beställning?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <Typography variant={'subtitle2'} component={'span'}>
            Detta kommer att ta bort pågående beställning. En raderad
            beställning går inte att återskapa eller återuppta.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={handleConfirm} variant={'contained'}>
          Radera
        </Button>
        <Button fullWidth onClick={handleAbort} variant={'outlined'}>
          Avbryt
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AbortOrderDialogue;

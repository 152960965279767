import FormValues, { MediaType } from '../../types/FormValues.type';

export const initialMusicValues: FormValues = {
  illustrations: [],
  parallelText: false,
  unknownLanguage: false,
  mediaType: MediaType.Music,
  isbn: '',
  noIsbn: false,
  hasAppendixes: null,
  notes: '',
  images: [
    { file: null, description: 'Framsida' },
    { file: null, description: 'Baksida' },
    { file: null, description: 'Skiva' },
  ],
  appendixes: [],
  numberOfLabels: null,
  labels: false
};

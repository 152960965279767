import React, { useState, useContext } from 'react';
import {
  Container,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Snackbar,
} from '@material-ui/core';
import { useFormik } from 'formik';

// Data & Types
import BookReviewValidationSchema from './BookReview/BookReviewValidation.schema';
import initialBookReviewValues from './BookReview/BookReviewInitialvalues';
import IsbnValues from '../types/IsbnForm.type';
import textContent from '../assets/textContent.json';

import { validateISBN } from '../helpers/validateISBN';
import webBgImage from '../assets/images/recensionsbanken/web.jpg';
import mobilBgImage from '../assets/images/recensionsbanken/mobil.jpg';

// Components
import FormHeader from '../components/FormHeader';
import FormNavigation from '../components/FormNavigation';
import { useNavigate } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import LoadingModal from '../components/LoadingModal';
import { getBookReviewByCode } from '../services/BookReview/BookReview.service';
import FormPage from '../components/FormPage';
import IsbnField from '../components/IsbnField';

import BtjContext from '../context/BtjContext';
import LoadingType from '../types/LoadingType';
import GAEvent from '../helpers/GAEvent';

const ROUTES = ['result'];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reviewBackground: {
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundImage: `url('${mobilBgImage}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 20% bottom 40%',
      position: 'fixed',
      top: '0',
      left: '0',
      zIndex: -2000,
      '@media (min-width:376px)': {
        backgroundImage: `url('${webBgImage}')`,
      },
    },
    formPaper: {
      background: 'none',
      border: 'none',
      height: '100%',
      paddingTop: theme.spacing(12),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      '& form': {
        flex: 1,
      },
    },
  })
);

function BookReviewSearch() {
  const [isAutoSearch, setIsAutoSearch] = useState(false);
  const { userProfile, setReview } = useContext(BtjContext);
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setErrors,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormik<IsbnValues>({
    initialValues: initialBookReviewValues,
    validationSchema: BookReviewValidationSchema,
    onSubmit: submitForm,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackbarStatus, setSnackbarStatus] = useState<{
    severity: 'success' | 'info' | 'warning' | 'error';
    text: string;
    isShowing: boolean;
  }>({ severity: 'info', text: '', isShowing: false });

  const navigate = useNavigate();
  const {
    pageHeader,
    pageInstruction,
    helperHeader,
    helperItems,
  } = textContent.bookReviewSearch;

  const registerGAEvent = (isbn:string|null, found:string) => {
    GAEvent('Book search', {
      customerName: !!userProfile ? userProfile.customerName : 'anonymous',
      appenService: 'Recensionsbanken',
      isbn,
      success: found === 'warning' ? 'not found' : found,
    });
  }

  const resetErrors = () => setErrors({});

  async function submitForm(values: IsbnValues) {
    setIsLoading(true);
    //@ts-ignore
    getBookReviewByCode(values.isbn).then((result) => {
      setIsLoading(false);
      if (!!result) {
        registerGAEvent(values.isbn, 'success');
        setReview && setReview(result);
        navigate('result');
      } else if (result === null) {
        registerGAEvent(values.isbn, 'warning');
        setSnackbarStatus({
          severity: 'warning',
          text: 'Titeln saknas',
          isShowing: true,
        });
      } else {
        registerGAEvent(values.isbn, 'error');
        setSnackbarStatus({
          severity: 'error',
          text: 'Någonting gick fel! Försök igen',
          isShowing: true,
        });
      }
    });
  }

  function blurAndValidate(event: React.FocusEvent<HTMLInputElement>) {
    handleBlur(event);
    validateFields(values, [event.target.name], setFieldTouched, setFieldError);
  }

  function changeAndValidate(event: React.ChangeEvent<HTMLInputElement>) {
    handleChange(event);
    //@ts-ignore
    touched[event.target.name] &&
      validateFields(
        values,
        [event.target.name],
        setFieldTouched,
        setFieldError
      );
  }

  function handleSnackbarClose() {
    setSnackbarStatus({ ...snackbarStatus, isShowing: false });
  }

  function validateFields(
    values: IsbnValues,
    targetPaths: Array<string>,
    setFieldTouched: (targetPath: string, touched: boolean) => void,
    setFieldError: (
      targetPath: string,
      errorMessage: string | undefined
    ) => void
  ): boolean {
    let error: string | undefined = undefined;

    targetPaths.forEach((targetPath) => {
      setFieldTouched(targetPath, true);
      setFieldError(targetPath, undefined);

      //params: value, isISBN, isOrderFlow
      error = validateISBN(values.isbn?.toString(), true, false);

      if (error) {
        setFieldError(targetPath, error);
      }
    });

    return !!error;
  }

  return (
    <Container style={{ height: '100%' }} maxWidth={'sm'}>
      <LoadingModal loadingType={LoadingType.Review} isLoading={isLoading} />
      <Snackbar
        open={snackbarStatus.isShowing}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          variant='filled'
          severity={snackbarStatus.severity}>
          {snackbarStatus.text}
        </Alert>
      </Snackbar>
      <div className={classes.reviewBackground}></div>
      <Paper className={classes.formPaper}>
        <FormHeader textContent={'RECENSIONSBANKEN'} />
        <FormPage
          header={pageHeader}
          instruction={pageInstruction}
          helperHeader={helperHeader}
          helperItems={helperItems}>
          <IsbnField
            onChange={changeAndValidate}
            error={errors.isbn}
            isbnValue={values.isbn}
            noIsbnValue={null}
            touched={touched.isbn}
            handleBlur={blurAndValidate}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            setFieldTouched={setFieldTouched}
            isOrderFlow={false}
            setIsAutoSearch={setIsAutoSearch}
          />
        </FormPage>
        <FormNavigation
          isOrderFlow={false}
          resetErrors={resetErrors}
          activeFields={[]}
          errors={errors}
          routes={ROUTES}
          submit={() => submitForm(values)}
          validateFields={(targetPaths) =>
            validateFields(values, targetPaths, setFieldTouched, setFieldError)
          }
          isAutoSearch={isAutoSearch}
          setIsAutoSearch={setIsAutoSearch}
        />
      </Paper>
    </Container>
  );
}

export default BookReviewSearch;

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import GradeIcon from '@material-ui/icons/Grade';

import { Review } from '../../../types/BookReview';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    border: 'none',
    borderRadius: 0,
    paddingBottom: '6rem',
    paddingTop: '.5rem',
  },
  title: { marginBottom: '1.5rem' },
  reviewItem: {
    marginBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

type SpecialistGradeProps = {
  reviews: Array<Review>;
};

function SpecialistGrade({ reviews }: SpecialistGradeProps) {
  const classes = useStyles();

  const mapBooksGrade = (grade: number) => {
    const booksGrade = [];

    for (let i = 1; i <= 5; i++) {
      const color = i <= grade ? '#494949' : '#afafaf';
      booksGrade.push(<GradeIcon key={i} style={{ color: color }} />);
    }
    return booksGrade;
  };

  return (
    <Paper className={classes.root}>
      <Typography className={classes.title} variant={'h2'} component={'h2'}>
        Helhetsbetyg
      </Typography>
      <Grid container direction='column'>
        {reviews.length > 0 && reviews.some((r) => !!r.grade) ? (
          reviews
            .filter((r) => !!r.grade)
            .map(({ author, grade }, index) => {
              return (
                <Grid key={index} className={classes.reviewItem}>
                  <Typography variant={'body2'} component={'label'}>
                    {author}
                  </Typography>
                  <Typography variant={'body2'} component={'span'}>
                    {grade && mapBooksGrade(grade)}
                  </Typography>
                </Grid>
              );
            })
        ) : (
          <Typography variant={'body2'} component={'h3'}>
            För denna titel finns inget betyg
          </Typography>
        )}
      </Grid>
    </Paper>
  );
}

export default SpecialistGrade;

import appConfig from '../config.json'

const config = {
  apiUrl: appConfig.apiUrl.includes('WEB_SERVER_API_URL') ? process.env.REACT_APP_API_URL : appConfig.authorizationUrl,
  authorityUrl: appConfig.authorizationUrl.includes('AUTHORIZATION_URL') ? process.env.REACT_APP_AUTHORIZATION_URL : appConfig.authorizationUrl,
  version: appConfig.version.includes('APPLICATION_VERSION') ? 'DEV' : appConfig.version,
  environment: appConfig.applicationEnvironment.includes('APPLICATION_ENVIRONMENT') ? process.env.REACT_APP_APPLICATION_ENVIRONMENT : appConfig.applicationEnvironment,
  ga4TrackingId: appConfig.ga4TrackingId.includes('GA4_TRACKING_ID') ? process.env.GA4_TRACKING_ID : appConfig.ga4TrackingId,
}

export default config;

import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormHeader from '../components/FormHeader';
import LibraryInfo from './LibraryInfo/LibraryInfo';
import { Button, Container, Grid, Paper, Slide } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import ScrollToTopFab from '../components/ScrollToTopFab';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  formPaper: {
    border: 'none',
    minHeight: '100vh',
    paddingTop: theme.spacing(12),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  navPaper: {
    width: '100%',
    border: 'none',
  },
}));

function MyLibrary() {
  const classes = useStyles();

  return (
    <Slide direction={'left'} in={true}>
      <Container maxWidth={'sm'}>
        <ScrollToTopFab />
        <Paper className={classes.formPaper}>
          <FormHeader textContent={'MITT BIBLIOTEK'} />
          <Paper className={classes.navPaper}>
            <LibraryInfo />
            <Grid
              container
              className={classes.root}
              direction='row'
              justify='space-evenly'
              spacing={2}>
              <Grid item xs={4}>
                <Button component={Link} to='/' startIcon={<ArrowBack />}>
                  Tillbaka
                </Button>
              </Grid>
              <Grid item xs={8}></Grid>
            </Grid>
          </Paper>
        </Paper>
      </Container>
    </Slide>
  );
}

export default MyLibrary;

import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import config from '../helpers/makeConfig';

export const getBaseUrl = () =>
  `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? ':' : ''
  }${window.location.port}/`;

export const IDENTITY_CONFIG: UserManagerSettings = {
  client_id: 'btjAppenClient',
  redirect_uri: `${getBaseUrl()}`,
  response_type: 'id_token token',
  scope: 'openid profile btjAppenApi',
  authority: config.authorityUrl,
  revokeAccessTokenOnSignout: true,
  post_logout_redirect_uri: `${getBaseUrl()}`,
  automaticSilentRenew: true,
  silent_redirect_uri: `${getBaseUrl()}silent_renew.html`,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: false,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

import React, { useState } from 'react';
import { Link as RouteLink } from 'react-router-dom';

import {
  Button,
  createStyles,
  Dialog,
  Grid,
  Link,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { setCookieConsent } from '../helpers/cookieConsent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiDialog-paper': {
        border: 'none !important',
        background: 'none',
      },
    },
    paper: {
      backgroundColor: '#fff',
      padding: '0',
      height: '100%',
      width: '100%',
      border: 'none',
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    header: {
      flex: '0 100%',
      background: '#00635e',
      padding: '1.5rem 0',
      '@media (min-width: 420px)': {
        padding: '2rem 0',
      },
      '& h1': {
        color: '#fff',
        fontWeight: 'bold',
      },
    },
    content: {
      padding: '1.5rem 1.25rem .5rem 1.25rem',
      '@media (min-width: 420px)': {
        padding: '2rem 2.5rem 1rem 2.5rem',
      },
      flex: '0 100%',
      '& p': {
        textAlign: 'left',
        paddingTop: '.25rem',
        '& a': {
          color: '#e94e2c',
          fontWeight: 'bold',
        },
      },
    },
    footer: {
      marginBottom: '1.5rem',
      '& button': {
        padding: '0.5rem 2.25rem',
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'initial',
        fontSize: '1rem',
        borderRadius: '25px',
      },
    },
  })
);

function CookieConsent() {
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  return (
    <Dialog
      scroll='body'
      maxWidth={'xs'}
      aria-labelledby='Kakpolicy'
      className={classes.root}
      open={open}>
      <Paper className={classes.paper} variant='outlined'>
        <Grid className={classes.wrapper}>
          <Grid className={classes.header} item>
            <Typography component={'h1'} variant={'h1'} align='center'>
              Kakor i BTJ-appen
            </Typography>
          </Grid>
          <Grid className={classes.content} item>
            <Typography
              component={'p'}
              variant={'body2'}
              align='center'
              paragraph>
              Den här webbplatsen använder kakor från BTJ och Google Analytics.
              Kakor och liknande teknik behövs för att hantera
              <b> inloggning </b>
              och lyssningsfunktioner samt inställningar som du gör. Du kan
              blockera webbkakor genom att göra inställningar i webbläsaren. Det
              kan innebära att vissa tjänster på webbplatsen inte fungerar,
              t.ex. att du inte kan logga in.
            </Typography>

            <Typography
              component={'p'}
              variant={'body2'}
              align={'center'}
              paragraph>
              För mer information om hur vi använder kakor, klicka här:{' '}
              <Link component={RouteLink} to='/cookie-policy'>
                BTJ-appen kakpolicy
              </Link>
            </Typography>

            <Typography
              component={'p'}
              variant={'body2'}
              align={'center'}
              paragraph>
              För mer information om hur vi förhåller oss till personuppgifter,
              klicka här:{' '}
              <Link href='https://www.btj.se/personuppgifter'>
                BTJ Personuppgiftspolicy
              </Link>
            </Typography>
          </Grid>
          <Grid className={classes.footer} item>
            <Button
              color='primary'
              variant='contained'
              fullWidth
              onClick={() => {
                setCookieConsent('btj-appen', 'true');
                setOpen(false);
              }}>
              Acceptera
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
}

export default CookieConsent;

import React from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Theme,
  Typography,
} from '@material-ui/core';
import Quota from '../../types/QuotaInfo.type';
import ShadedTableRow from '../../components/ShadedTableRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '1rem  0 !important',
    },
    table: {
      borderStyle: 'none',
    },
    tableCell: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: 'none',
    },
    span: {
      flex: 1,
      textAlign: 'right',
      padding: '0.15rem',
      lineHeight: '1.25rem',
    },
    description: {
      lineHeight: '1.25rem',
    },
  })
);

function QuotaInfo({ prepaidQuota, usedQuota, quotaLeft }: Quota) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root} item>
        <Typography component={'h2'} variant={'h3'} paragraph>
          Fri katalogiseringskvot
        </Typography>
        <TableContainer className={classes.table} component={Paper}>
          <Table>
            <TableBody>
              <ShadedTableRow>
                <TableCell className={classes.tableCell}>
                  <Grid style={{ flex: 2 }} item>
                    <Typography
                      className={classes.description}
                      component={'span'}
                      variant={'subtitle1'}>
                      Totalt fri katalogiseringskvot:
                    </Typography>
                  </Grid>
                  <Grid container style={{ flex: 1 }} item>
                    <Typography
                      className={classes.span}
                      component={'span'}
                      variant={'subtitle2'}>
                      {prepaidQuota}&nbsp;st
                    </Typography>
                  </Grid>
                </TableCell>
              </ShadedTableRow>
              <ShadedTableRow>
                <TableCell className={classes.tableCell}>
                  <Grid style={{ flex: 2 }} item>
                    <Typography
                      className={classes.description}
                      component={'span'}
                      variant={'subtitle1'}>
                      Utnyttjad fri katalogiseringskvot:
                    </Typography>
                  </Grid>
                  <Grid container style={{ flex: 1 }} item>
                    <Typography
                      className={classes.span}
                      component={'span'}
                      variant={'subtitle2'}>
                      {usedQuota}&nbsp;st
                    </Typography>
                  </Grid>
                </TableCell>
              </ShadedTableRow>
              <ShadedTableRow>
                <TableCell className={classes.tableCell}>
                  <Grid style={{ flex: 2 }} item>
                    <Typography
                      className={classes.description}
                      component={'span'}
                      variant={'subtitle1'}>
                      Återstående fri katalogiseringskvot:
                    </Typography>
                  </Grid>
                  <Grid container style={{ flex: 1 }} item>
                    <Typography
                      className={classes.span}
                      component={'span'}
                      variant={'subtitle2'}>
                      {quotaLeft}&nbsp;st
                    </Typography>
                  </Grid>
                </TableCell>
              </ShadedTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item>
        <hr />
      </Grid>
    </>
  );
}

export default QuotaInfo;

import React, { ChangeEvent, useEffect, useContext } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import FormPage from '../../../components/FormPage';
import RadioButtons from '../../../components/RadioButtons';
import FormPageProps from '../../../types/FormPageProps.type';
import spiralhaftning from '../../../assets/images/spiralhaftning.jpg';
import BtjContext from '../../../context/BtjContext';
import Image from '../../../types/Image.type';

const buttonValues = [
  { label: 'Ja', value: true },
  { label: 'Nej', value: false },
];
function ContentForm({
  values,
  touched,
  errors,
  onChange,
  onBlur,
  setFieldError,
  setFieldValue,
  setActiveFields,
  textContent,
}: FormPageProps) {
  const {
    pageHeader,
    pageInstruction,
    helperHeader,
    helperItems,
  } = textContent.contentForm;
  helperItems[2].imageURL = spiralhaftning;
  const { setPromiseList } = useContext(BtjContext);

  useEffect(() => {
    setActiveFields([
      'tableOfContents',
      'hasIllustrations',
      'spiralBinding',
      'numberOfPages',
    ]);
  }, [setActiveFields]);

  function handleRadioChange(event: ChangeEvent<HTMLInputElement>) {
    setFieldError(event.target.name, undefined);
    setFieldValue(event.target.name, event.target.value === 'true');
  }

  function handleHasTableOfContentsChange(
    event: React.ChangeEvent<HTMLInputElement>,
    newContentName: string
  ) {
    if (event.target.value === 'true') {
      setFieldValue('images', [
        ...values.images,
        { file: null, description: newContentName },
      ]);
    } else {
      setFieldValue('images', [
        ...values.images.filter(
          (image) => image.description !== newContentName
        ),
      ]);

      setPromiseList((value: Array<Image>) => [
        ...value.filter(
          (item: Image) => item.extendedId !== `${newContentName}-camera`
        ),
      ]);
    }
    handleRadioChange(event);
  }

  function handlePaginationUnknown(paginationUnknown: boolean) {
    if (paginationUnknown) {
      setFieldValue('numberOfPages', null);
      setFieldValue('images', [
        ...values.images,
        { file: null, description: 'Sista numrerade sida' },
      ]);
    } else {
      setFieldValue('images', [
        ...values.images.filter(
          (image) => image.description !== 'Sista numrerade sida'
        ),
      ]);

      setPromiseList((value: Array<Image>) => [
        ...value.filter(
          (item: Image) => item.extendedId !== 'Sista numrerade sida-camera'
        ),
      ]);
    }
    setFieldValue('paginationUnknown', paginationUnknown);
  }

  function handlePaginationMissing(paginationMissing: boolean) {
    if (paginationMissing) {
      setFieldValue('numberOfPages', '');
      setFieldValue('paginationUnknown', false);
    }
    setFieldValue('paginationMissing', paginationMissing);
  }

  return (
    <FormPage
      header={pageHeader}
      instruction={pageInstruction}
      helperHeader={helperHeader}
      helperItems={helperItems}>
      <Grid item xs={12}>
        <RadioButtons
          name={'tableOfContents'}
          value={values.tableOfContents}
          onChange={(event) =>
            handleHasTableOfContentsChange(event, 'Innehållsförteckning')
          }
          touched={touched.tableOfContents}
          onBlur={onBlur}
          error={errors.tableOfContents}
          text={'Har boken en innehållsförteckning?'}
          alternatives={[
            { label: 'Ja', value: true },
            { label: 'Nej', value: false },
          ]}
          small
        />
      </Grid>
      <Grid item>
        <RadioButtons
          name={'hasIllustrations'}
          text={'Innehåller boken fler än tre illustrationer?'}
          value={values.hasIllustrations}
          error={errors.hasIllustrations}
          touched={touched.hasIllustrations}
          onBlur={onBlur}
          alternatives={[
            { label: 'Ja', value: true },
            { label: 'Nej', value: false },
          ]}
          small
          onChange={(event) =>
            handleHasTableOfContentsChange(event, 'Illustration')
          }
        />
      </Grid>
      <Grid item>
        <RadioButtons
          name={'spiralBinding'}
          text={'Är boken spiralbunden?'}
          value={values.spiralBinding}
          error={errors.spiralBinding}
          touched={touched.spiralBinding}
          onBlur={onBlur}
          alternatives={buttonValues}
          small
          onChange={handleRadioChange}
        />
      </Grid>

      <Grid container item direction={'row'} spacing={2} alignItems={'center'}>
        <Grid item xs={12}>
          <Typography variant={'h3'} component={'h3'}>
            Hur många sidor har boken?
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            inputProps={{ maxLength: '20' }}
            disabled={values.paginationUnknown}
            name={'numberOfPages'}
            value={values.numberOfPages || ''}
            error={!!errors.numberOfPages && touched.numberOfPages}
            placeholder='Antal sidor'
            onChange={onChange}
            onBlur={onBlur}
            label='Sidantal'
            type='text'
            helperText={
              !!errors.numberOfPages &&
              touched.numberOfPages &&
              errors.numberOfPages
            }
          />
        </Grid>
        <Grid item xs={8}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={values.paginationUnknown}
                checked={values.paginationMissing}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handlePaginationMissing(event.target.checked)
                }
                name='paginationMissing'
                color='secondary'
              />
            }
            label={
              <Typography variant={'h3'} component={'span'}>
                Sidnumrering saknas
              </Typography>
            }
          />
        </Grid>

        {values.paginationMissing && (
          <Grid item xs={12}>
            <Typography variant={'body1'} component={'p'} paragraph>
              Om sidnumrering saknas, räkna bokens sidor och ange antalet i
              fältet för sidantal. Om boken har fler än 100 onumrerade sidor,
              skriv istället "1 volym" i fältet för sidantal.
            </Typography>
          </Grid>
        )}
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.paginationUnknown}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handlePaginationUnknown(event.target.checked)
                }
                name='pagesUnknown'
                color='secondary'
                disabled={values.paginationMissing}
              />
            }
            label={
              <Typography variant={'h3'} component={'span'}>
                Sidantalet står på ett språk jag inte förstår.
              </Typography>
            }
          />
        </Grid>
      </Grid>

      {values.paginationUnknown && (
        <Grid item xs={12}>
          <Typography variant={'body1'} component={'p'}>
            Om bokens sidnumrering är skriven på ett språk du inte förstår, ska
            du i nästa steg fotografera bokens sista numrerade sida.
          </Typography>
        </Grid>
      )}
    </FormPage>
  );
}

export default ContentForm;

import React from 'react';
import { createStyles, makeStyles, TableCell, TableRow, Theme, Typography } from '@material-ui/core';
import FormValues from '../types/FormValues.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
);
type Props = {
  isbnValue: FormValues['isbn'];
};

function ResultsTableIsbn({ isbnValue }: Props) {
  const classes = useStyles();
  return (
    <TableRow className={classes.root}>
      <TableCell>
        <Typography variant={'subtitle2'} component={'span'}>
          {isbnValue && isbnValue.length >= 10
            ? isbnValue
            : '- - - - - - - - - -'}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default ResultsTableIsbn;

import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import OrderCard from './OrderCard';
import OrderHistoryValues from '../../../types/OrderHistoryValues.type';
import getOngoingOrdersFromApi from '../../../services/OrderHistory/OngoingOrders.service';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(),
    },
  })
);

function OngoingOrders() {
  const classes = useStyles();
  const [orders, setOrders] = useState<OrderHistoryValues[]>();

  useEffect(() => {
    getOngoingOrdersFromApi().then(
      (newOrders) => newOrders && setOrders(newOrders)
    );
  }, []);

  return (
    <Grid container direction={'column'} spacing={1}>
      {orders &&
        orders.map((order, index) => (
          <Grid key={index} item>
            <OrderCard {...order} />
          </Grid>
        ))}
      {orders?.length === 0 && (
        <Typography className={classes.root} align='center' variant='subtitle1'>
          Det finns inga pågående ordrar
        </Typography>
      )}
    </Grid>
  );
}

export default OngoingOrders;

import { BookReview } from '../../types/BookReview';

export async function getBookReviewByCode(
  code: string | number,
  isIsbn: boolean = true
): Promise<BookReview | undefined | null> {
  try {
    const params = isIsbn ? `isbn/${code.toString().replace(/-/g, '')}` : code;
    const res = await fetch(`/api/v1/reviewbank/Publication/${params}`, {
      method: 'GET',
    });

    if (res.status === 200) {
      return res.json();
    }

    if (res.status === 204) {
      return null;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
}

export async function getBookReviewImage(imageId: number): Promise<any> {
  try {
    const res = await fetch(`/api/v1/reviewbank/Media/front/${imageId}.gif`, {
      method: 'GET',
    });

    if (res.ok) {
      const blob = await res.blob();

      return blob;
    }
    return null;
  } catch (error) {
    return null;
  }
}

export async function getBookReviewByOCR(
  file: File,
  signal: AbortSignal
): Promise<any> {
  const formData = new FormData();
  formData.set('formFile', file);

  try {
    const res = await fetch('/api/v1/Ocr/isbn', {
      signal: signal,
      method: 'POST',
      body: formData,
      headers: {
        accept: 'text/plain',
      },
    });

    if (res.status === 200) {
      return res.json();
    }

    if (res.status === 218) {
      return null;
    }

    return undefined;
  } catch (err) {
    return undefined;
  }
}

import React, {
  ChangeEventHandler,
  FocusEventHandler,
  useState,
  useEffect,
  useContext,
} from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import FormValues from '../types/FormValues.type';
import IconButton from '@material-ui/core/IconButton';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CodeReaderModal from './CodeReaderModal';
import FormPageProps from '../types/FormPageProps.type';
import CustomSnackBar from './CustomSnackBar';
import { isBarCodeValid, invalidBarCode } from '../helpers/validateISBN';
import BtjContext from '../context/BtjContext';
import GAEvent from '../helpers/GAEvent';

type Props = {
  error: string | undefined;
  isbnValue: FormValues['isbn'];
  noIsbnValue: FormValues['noIsbn'];
  onChange: ChangeEventHandler;
  handleBlur: FocusEventHandler<HTMLInputElement>;
  handleNoIsbn?: ChangeEventHandler<HTMLInputElement>;
  touched: boolean | undefined;
  setFieldValue: FormPageProps['setFieldValue'];
  setFieldError: FormPageProps['setFieldError'];
  setFieldTouched: FormPageProps['setFieldTouched'];
  isOrderFlow?: boolean;
  setIsAutoSearch?: Function;
};

function IsbnField({
  error,
  isbnValue,
  noIsbnValue,
  onChange,
  touched,
  handleBlur,
  handleNoIsbn,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  isOrderFlow = true,
  setIsAutoSearch,
}: Props) {
  const [openBCRModal, setOpenBCRModal] = useState(false);
  const [code, setCode] = useState<string | null>(null);
  const { userProfile } = useContext(BtjContext);

  useEffect(() => {
    if (
      (isOrderFlow && code && isBarCodeValid(code)) ||
      (!isOrderFlow && code)
    ) {
      handleISBNField(code.replace('OCR', ''), true, undefined);
      setCode(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, noIsbnValue, isOrderFlow]);

  const handleISBNField = (
    value: string | null = null,
    touched: boolean = false,
    error: string | undefined = undefined
  ) => {

    setFieldValue('isbn', value);
    if (!isOrderFlow) {
      setIsAutoSearch && setIsAutoSearch(!!value);
    }

    //@ts-ignore
    setFieldTouched('isbn', touched);
    setFieldError('isbn', error);
  };

  const registerGAEvent = () => {
    GAEvent('Enter isbn', {
      customerName: userProfile ? userProfile.customerName : 'anonymous',
      appenService: 'Recensionsbanken',
      actionDetail: 'manual',
    });
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TextField
          onKeyPress={(ev) => {
            if (ev.key === 'Enter' && !isOrderFlow) {
              registerGAEvent();
              setIsAutoSearch && setIsAutoSearch(true);
              ev.preventDefault();
            }
          }}
          onBlur={handleBlur}
          autoFocus={false}
          autoComplete={'off'}
          error={!noIsbnValue && !!error}
          helperText={
            !noIsbnValue && error
              ? error
              : 'ISBN ska bestå av 10-13 tecken och anges alltid utan bindestreck eller mellanrum.'
          }
          name={'isbn'}
          type='text'
          label='ISBN'
          value={isbnValue || ''}
          onChange={onChange}
          disabled={!!noIsbnValue}
          fullWidth
          InputProps={{
            style: { background: '#fff' },
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  disabled={!!noIsbnValue}
                  aria-label='Scanner'
                  onClick={() => {
                    handleISBNField(null, false, undefined);
                    setOpenBCRModal(true);
                  }}>
                  <CameraAltIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {openBCRModal ? (
          <CodeReaderModal
            open={openBCRModal}
            onClose={setOpenBCRModal}
            onCodeRead={setCode}
          />
        ) : null}
        {isOrderFlow && code && !isBarCodeValid(code) ? (
          <CustomSnackBar
            type='error'
            message={invalidBarCode}
            onClose={() => setCode(null)}
          />
        ) : null}
      </Grid>
      {isOrderFlow && (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!noIsbnValue}
                onChange={handleNoIsbn}
                name='noISBN'
                color='secondary'
              />
            }
            label={
              <Typography variant={'h3'} component={'span'}>
                ISBN saknas
              </Typography>
            }
          />
        </Grid>
      )}
    </Grid>
  );
}

export default IsbnField;

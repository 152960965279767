import React, { useEffect } from 'react';
import FormPage from '../../../components/FormPage';
import PhotoAccordion from '../../../components/PhotoAccordion';
import FormPageProps from '../../../types/FormPageProps.type';
import handleImageFileChange from '../../../helpers/handleImageFileChange';
import titelsida_1 from '../../../assets/images/Titelsida_1.jpg';
import kolofon_1 from '../../../assets/images/kolofon_1.jpg';
import castJSONString from '../../../helpers/castJSONString';

function PhotoForm({
  values,
  errors,
  setFieldValue,
  setActiveFields,
  textContent,
}: FormPageProps) {
  const {
    pageHeader,
    pageInstruction,
    helperHeader,
    helperItems,
  } = textContent.photoForm;
  helperItems[2].imageURL = titelsida_1;
  helperItems[3].imageURL = kolofon_1;

  useEffect(() => {
    setActiveFields(['images']);
  }, [setActiveFields]);

  return (
    <FormPage
      header={pageHeader}
      instruction={pageInstruction}
      helperHeader={helperHeader}
      helperItems={helperItems}>
      <PhotoAccordion
        handleImageChange={(event, index, setPromiseList) =>
          handleImageFileChange(
            values,
            'images',
            setFieldValue,
            event,
            index,
            setPromiseList
          )
        }
        images={values.images}
        errors={errors.images && castJSONString(errors.images)}
      />
    </FormPage>
  );
}

export default PhotoForm;

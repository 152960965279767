import React from 'react';
import FormPage from '../../../components/FormPage';
import FormValues from '../../../types/FormValues.type';
import {
  Checkbox, createStyles,
  FormControlLabel,
  makeStyles,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import ResultsTableBlock from '../../../components/ResultsTableBlock';
import ResultsTableIsbn from '../../../components/ResultsTableIsbn';
import ResultsTableLanguages from '../../../components/ResultsTableLanguages';
import ResultsTableContents from '../../../components/ResultsTableContents';
import ResultsTableImages from '../../../components/ResultsTableImages';
import { FormikErrors, FormikTouched } from 'formik';

type Props = {
  values: FormValues;
  touched: FormikTouched<FormValues>;
  errors: FormikErrors<FormValues>;
  onChange: React.ChangeEventHandler;
  onBlur: React.FocusEventHandler;
  setFieldValue: (fieldName: string, value: any) => void;
  textContent: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftCell: {
      paddingRight: 0,
      width: '60%',
    },
    rightCell: {
      paddingLeft: 0,
    },
  })
);

function BookResultsPage({
                       values,
                       touched,
                       errors,
                       onChange,
                       onBlur,
                       setFieldValue,
                      textContent
                     }: Props) {
  const { pageHeader, pageInstruction, helperHeader, helperItems} = textContent.resultsForm;
  const handleChange = onChange;
  const handleBlur = onBlur;
  const classes = useStyles();
  return (
    <FormPage
      header={pageHeader}
      instruction={pageInstruction}
      helperHeader={helperHeader}
      helperItems={helperItems}>
      <ResultsTableBlock header={'ISBN/EAN'}>
        <ResultsTableIsbn isbnValue={values.isbn} />
      </ResultsTableBlock>
      <ResultsTableBlock header={'Språk'}>
        <ResultsTableLanguages
          parallelText={values.parallelText}
          languages={values.languages}
        />
      </ResultsTableBlock>
      <ResultsTableBlock header={'Bokens innehåll'}>
        <ResultsTableContents
          tableOfContents={values.tableOfContents}
          numberOfPages={values.numberOfPages}
          hasIllustrations={values.hasIllustrations}
          spiralbinding={values.spiralBinding}
        />
      </ResultsTableBlock>
      <ResultsTableBlock
        header={`Bilder ${values.hasAppendixes ? ' och bilagor' : ''}`}>
        <ResultsTableImages
          images={[
            ...values.images,
            ...values.illustrations,
            ...values.appendixes,
          ]}
        />
      </ResultsTableBlock>
      <ResultsTableBlock header={'Behöver du etikett till beställningen?'}>
        <TableRow>
          <TableCell className={classes.leftCell} align='left'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.labels}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                      setFieldValue('labels', true);
                      setFieldValue('numberOfLabels', 1);
                    }
                    if (!e.target.checked) {
                      setFieldValue('labels', false);
                      setFieldValue('numberOfLabels', null);
                    }
                  }}
                  name='labels'
                />
              }
              label={
                <Typography variant={'subtitle1'} component={'span'}>
                  Ja, skicka etikett
                </Typography>
              }
            />
          </TableCell>
          <TableCell className={classes.rightCell} align='left'>
            <TextField
              fullWidth
              onBlur={handleBlur}
              error={!!errors.numberOfLabels && touched.numberOfLabels}
              helperText={touched.numberOfLabels && errors.numberOfLabels}
              value={values.numberOfLabels || ''}
              label={!!values.numberOfLabels && 'Antal etiketter'}
              name={'numberOfLabels'}
              onChange={handleChange}
              disabled={!values.labels}
              type={'number'}
              inputProps={{ max: 100, min: 1 }}
            />
          </TableCell>
        </TableRow>
      </ResultsTableBlock>
      <ResultsTableBlock header={'Övriga kommentarer till katalogisatören'}>
        <TableRow>
          <TableCell>
            <TextField
              value={values.notes}
              onBlur={handleBlur}
              error={!!errors.notes && touched.notes}
              helperText={!!errors.notes && touched.notes && errors.notes}
              label={'Kommentar'}
              name={'notes'}
              onChange={handleChange}
              multiline={true}
              rows={2}
              rowsMax={10}
              fullWidth={true}
            />
          </TableCell>
        </TableRow>
      </ResultsTableBlock>
    </FormPage>
  );
}

export default BookResultsPage;

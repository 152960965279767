import FormValues from '../types/FormValues.type';
import React from 'react';
import { postImage } from '../services/Image.service';
import compressImage from './compressImage';
import Image from '../types/Image.type';

export default async function handleImageFileChange(
  values: FormValues,
  targetPath: string,
  setFieldValue: (fieldName: string, value: any) => void,
  event: React.ChangeEvent<HTMLInputElement>,
  index: number,
  setPromiseList: Function
) {
  if (event.target.files && event.target.files[0]) {
    try {
      const newFile = await compressImage(event.target.files[0]);

      //@ts-ignore
      const nextImages = [...values[targetPath]];
      nextImages[index].file = newFile;

      setFieldValue(targetPath, nextImages);

      const postImagePromise = postImage(newFile);

      setPromiseList((value: Array<Image>) => [
        ...value.filter(
          (item: Image) => !(item.category === targetPath && item.id === index)
        ),
        {
          id: index,
          promise: postImagePromise,
          category: targetPath,
          extendedId: event.target.id,
        },
      ]);
    } catch (error) {
      console.log('compression has failed');
    }
  }
}

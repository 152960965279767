import FormValues from '../types/FormValues.type';
import validateImages from './validateImages';
import validateLanguages from './validateLanguages';
import { validateISBN } from './validateISBN';

export default function validateFields(
  validationSchema: any,
  values: FormValues,
  targetPaths: Array<string>,
  setFieldTouched: (targetPath: string, touched: boolean) => void,
  setFieldError: (targetPath: string, errorMessage: string | undefined) => void,
  isISBN: boolean = false
): boolean {
  const errors: Array<string> = [];
  let error: string | undefined = undefined;

  targetPaths.forEach((targetPath) => {
    setFieldTouched(targetPath, true);
    setFieldError(targetPath, undefined);
    if (
      targetPath === 'images' ||
      targetPath === 'appendixes' ||
      targetPath === 'illustrations'
    ) {
      error = validateImages(values, targetPath);
    } else if (targetPath === 'languages') {
      error = validateLanguages(
        values.languages,
        values.parallelText,
        values.unknownLanguage,
        setFieldError
      );

      if (!!error) {
        errors.push(error);
        error = undefined;
      }
    } else if (targetPath === 'isbn' && !values.noIsbn) {
      error = validateISBN(values.isbn, isISBN);
    } else {
      try {
        validationSchema.validateSyncAt(targetPath, values);
      } catch (err) {
        error = err.errors[0];
      }
    }

    if (error) {
      setFieldError(targetPath, error);
      errors.push(error);
      error = undefined;
    }
  });

  return errors.length > 0;
}

import { useState, useEffect } from 'react';
import { Snackbar, Theme, makeStyles, createStyles } from '@material-ui/core';
import MuiAlert, { Color } from '@material-ui/lab/Alert';

type Props = {
  type: Color;
  message: any;
  onClose?: Function;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    messageItem: { flex: '0 0 100%' },
  })
);

function CustomSnackBar({ type, message, onClose }: Props) {
  const classes = useStyles();
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(true);

  useEffect(() => {
    return () => {
      onClose && onClose();
    };
  });

  const handleOnClose = () => {
    setSnackBarOpen(false);
  };

  function renderMessage() {
    return typeof message === 'object' ? (
      <div className={classes.message}>
        {Object.values(message).map((messageItem, index) => {
          return renderMessageList(messageItem, index);
        })}
      </div>
    ) : (
      <div className={classes.message}>{message}</div>
    );
  }

  function renderMessageList(messageItem: any, i: number) {
    try {
      const messageItemList = JSON.parse(messageItem);
      return messageItemList.map((messageItem: string, si: number) =>
        renderMessageListItem(messageItem, `${i}:${si}`)
      );
    } catch (e) {
      return renderMessageListItem(messageItem, `${i}`);
    }
  }

  function renderMessageListItem(str: string, i: string) {
    return (
      <span className={classes.messageItem} key={i}>
        {str}
      </span>
    );
  }

  return (
    <Snackbar
      open={snackBarOpen}
      autoHideDuration={6000}
      onClose={handleOnClose}>
      <MuiAlert
        elevation={6}
        variant='filled'
        onClose={handleOnClose}
        severity={type}>
        {renderMessage()}
      </MuiAlert>
    </Snackbar>
  );
}

export default CustomSnackBar;

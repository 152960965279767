import FormPageProps from '../../types/FormPageProps.type';
import React, { ChangeEvent, useEffect } from 'react';
import FormPage from '../../components/FormPage';
import EanField from '../../components/EanField';
import PhotoAccordion from '../../components/PhotoAccordion';
import handleImageFileChange from '../../helpers/handleImageFileChange';
import { Grid, Typography } from '@material-ui/core';
import castJSONString from '../../helpers/castJSONString';

function MediaContentPage({
  values,
  validate,
  touched,
  errors,
  onChange,
  onBlur,
  setFieldError,
  setFieldValue,
  setActiveFields,
  initialValues,
  textContent,
  setFieldTouched,
}: FormPageProps) {
  const {
    pageHeader,
    pageInstruction,
    helperHeader,
    helperItems,
  } = textContent.contentForm;
  const mediumName = textContent.mediumName;
  useEffect(() => {
    setActiveFields(['isbn', 'images']);
  }, [setActiveFields]);

  function handleNoIsbn(event: ChangeEvent<HTMLInputElement>) {
    const noIsbn = event.target.checked;
    setFieldValue('noIsbn', noIsbn);
    if (noIsbn) {
      setFieldValue('isbn', null);
    } else {
      setFieldValue('isbn', '');
    }
    validate(['isbn']);
  }

  return (
    <FormPage
      header={pageHeader}
      instruction={pageInstruction}
      helperHeader={helperHeader}
      helperItems={helperItems}>
      <Grid container direction={'column'} spacing={1}>
        <Grid item>
          <EanField
            onChange={onChange}
            error={errors.isbn}
            isbnValue={values.isbn}
            noIsbnValue={values.noIsbn}
            touched={touched.isbn}
            handleBlur={onBlur}
            handleNoIsbn={handleNoIsbn}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            setFieldTouched={setFieldTouched}
          />
        </Grid>
        <Grid item>
          <Typography variant={'body2'} component={'p'} paragraph>
            {`Fotografera omslagets framsida och baksida samt ${mediumName}. Om det
            finns fler egenskaper kan ytterligare fotografier
            läggas till i ett senare skede.`}
          </Typography>
        </Grid>
        <Grid item>
          <PhotoAccordion
            handleImageChange={(event, index, setPromiseList) =>
              handleImageFileChange(
                values,
                'images',
                setFieldValue,
                event,
                index,
                setPromiseList
              )
            }
            images={values.images}
            errors={errors.images && castJSONString(errors.images)}
          />
        </Grid>
      </Grid>
    </FormPage>
  );
}

export default MediaContentPage;

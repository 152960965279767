import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import textContent from '../../../assets/textContent.json';
import HelpButton from '../../../components/HelpButton';
import { Field, Series } from '../../../types/BookReview';

import { getBookReviewImage } from '../../../services/BookReview/BookReview.service';

import FieldRow from '../../../components/FieldRow';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: '.5rem',
  },
  titles: {
    paddingBottom: '.5rem',
  },
  helpButton: {
    '& div button': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
  },
  image: {
    padding: '.35rem 1rem 0 0',
    '& div': {
      width: '80px',
      height: '120px',
      '@media(min-width:375px)': {
        width: '100px',
        height: '160px',
      },
      '@media(min-width:768px)': {
        width: '120px',
        height: '200px',
      },
      '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
        display: 'block',
      },
    },
  },
  summaryInfo: {
    flex: 'auto',
    paddingRight: '1rem',
  },
  accordion: {
    border: 'none',
    flex: 1,
    margin: '0 !important',
    position: 'relative',
    '&:before': {
      background: 'none !important',
    },
  },
  accordionRoot: {
    padding: 0,
    minHeight: '0 !important',
    '&:first-child': {
      maxWidth: '95%',
    },
  },
  accordionContent: {
    margin: '0 !important',
  },
  expandIconWrapper: {
    padding: 0,
    position: 'absolute',
    right: '-2px',
    top: '.75rem',
  },
  author: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  bookInfo: {
    color: 'rgba(0, 0, 0, 0.54)',
    textTransform: 'uppercase',
  },
  labelText: {
    fontWeight: 'bold',
    marginRight: '.5rem',
    textTransform: 'capitalize',
  },
  seriesButton: {
    marginTop: '.8rem',
  },
  extraIsbns: {
    fontSize: '.75rem',
  },
  expandSummary: {
    flex: '0 100% !important',
    maxWidth: '100% !important',
  },
  accordionDetails: {
    padding: '.5rem 0',
  },
  isbnSpan: {
    paddingLeft: '.25rem',
  },
}));

type BookReviewSummaryProps = {
  title: string;
  subTitle: string;
  mediaType: string;
  imageTid: number | null;
  isbns: Array<string>;
  fields: Array<Field>;
  series: Series | null;
  handleTabIndex: Function;
};

function BookReviewSummary({
  title,
  subTitle,
  mediaType,
  imageTid,
  fields,
  isbns,
  series,
  handleTabIndex,
}: BookReviewSummaryProps) {
  const [bookCover, setBookCover] = useState<string | null>(null);

  const classes = useStyles();
  const { helperHeader, helperItems } = textContent.bookReview;

  useEffect(() => {
    const getImage = async () => {
      const result = imageTid && (await getBookReviewImage(imageTid));

      if (!!result) {
        const url = URL.createObjectURL(result);
        setBookCover(url);
      } else {
        setBookCover(null);
      }
    };

    if (imageTid) {
      getImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageTid]);

  return (
    <Grid className={classes.container} container>
      <Grid
        className={classes.titles}
        container
        direction='column'
        alignItems='flex-start'>
        <Grid item container alignItems='center'>
          <Grid item xs={11}>
            <Typography variant={'h2'} component={'h1'}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.helpButton}>
            {helperItems && (
              <HelpButton helperItems={helperItems} header={helperHeader} />
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Typography
            className={classes.author}
            variant={'h4'}
            component={'h3'}>
            {subTitle}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={classes.bookInfo}
            variant={'h5'}
            component={'h5'}>
            {mediaType}
          </Typography>
        </Grid>
      </Grid>
      <Grid container wrap='nowrap'>
        {bookCover ? (
          <Grid item className={classes.image}>
            <div>
              <img
                src={bookCover}
                alt=''
                onError={() => {
                  setBookCover(null);
                }}
              />
            </div>
          </Grid>
        ) : null}
        <Grid item container direction='column' className={classes.summaryInfo}>
          <Grid item>
            {isbns.length > 1 ? (
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  classes={{
                    root: classes.accordionRoot,
                    content: classes.accordionContent,
                    expandIcon: classes.expandIconWrapper,
                  }}
                  expandIcon={
                    <ExpandMoreIcon
                      style={{ padding: 0, position: 'absolute' }}
                    />
                  }>
                  <Typography
                    className={classes.labelText}
                    component={'label'}
                    variant={'body2'}>
                    ISBN/EAN:
                  </Typography>
                  <Typography
                    style={{ flex: 1 }}
                    component={'span'}
                    variant={'body2'}>
                    {isbns[0]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Grid container direction={'column'}>
                    <Typography
                      className={classes.extraIsbns}
                      component={'span'}
                      variant={'body2'}>
                      <b>Finns även utgiven under dessa ISBN:</b>
                    </Typography>
                    {isbns.map((isbn, index) => {
                      return index !== 0 ? (
                        <Typography
                          className={classes.isbnSpan}
                          key={index}
                          component={'span'}
                          variant={'body2'}>
                          {isbn}
                        </Typography>
                      ) : null;
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                <Typography
                  className={classes.labelText}
                  component={'label'}
                  variant={'body2'}>
                  ISBN/EAN:
                </Typography>
                <Typography component={'span'} variant={'body2'}>
                  {isbns[0]}
                </Typography>
              </>
            )}
          </Grid>
          {fields.map((item, index) => {
            return (
              <Grid
                key={index}
                container
                alignItems='flex-start'
                justify='flex-start'
                direction={!item.foldable ? 'row' : 'column'}
                item>
                <FieldRow {...item} />
              </Grid>
            );
          })}
          {!!series ? (
            <Grid item>
              <Button
                className={classes.seriesButton}
                endIcon={<CollectionsBookmarkIcon />}
                fullWidth
                onClick={() => {
                  handleTabIndex();
                }}>
                <Typography component={'h3'} variant={'body2'}>
                  Del av en serie
                </Typography>
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default BookReviewSummary;

import React, { useEffect, useState } from 'react';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { useAuth } from 'oidc-react';
import bg from '../assets/images/homebg.jpg';
import logo from '../assets/logo.svg';
import textContent from './../assets/textContent.json';
import HelpButton from '../components/HelpButton';
import Dashboard from './Dashboard';
import Login from './Login';

import CookieConsent from '../components/CookieConsent';
import Cookies from 'universal-cookie';
import {
  isSessionOutdated,
  removeSessionStatus,
} from '../services/Auth.service';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      width: '100vw',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(4),
      backgroundImage: `url('${bg}')`,
      backgroundPosition: 'right 15% bottom 50%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    header: {
      fontSize: '2rem',
      fontWeight: theme.typography.fontWeightBold,
    },
    logo: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: '5vh',
      width: '50%',
      maxWidth: '130px',
    },
    menuContainer: {
      maxWidth: '300px',
    },
    info: {
      position: 'absolute',
      right: '0',
      top: '0',
    },
  })
);

function Home() {
  const [isCookieAccepted, setIsCookieAccepted] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<null | boolean>(null);
  const classes = useStyles();
  const auth = useAuth();
  const { helperHeader, helperItems } = textContent.btjHome;

  useEffect(() => {
    const checkLoginRequired = async () => {
      if (isSessionOutdated() && window.location.pathname === '/') {
        removeSessionStatus();
        await auth.userManager.removeUser();
        setIsLoggedIn(false);
      }
    };

    auth.userManager.events.addSilentRenewError(() => {
      checkLoginRequired();
    });

    auth.userManager.events.addAccessTokenExpired(() => {
      checkLoginRequired();
    });

    checkLoginRequired();
  }, [auth.userManager]);

  useEffect(() => {
    const checkUser = async () => {
      const user = await auth.userManager.getUser();
      if (user && !user.expired) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    };

    checkUser();
  }, [auth]);

  useEffect(() => {
    function disableNav() {
      window.history.pushState(null, document.title, window.location.href);
    }

    disableNav();
    window.addEventListener('popstate', disableNav);

    return () => {
      window.removeEventListener('popstate', disableNav);
    };
  }, []);

  useEffect(() => {
    const _cookies = new Cookies();

    if (!!_cookies.get('btj-appen')) {
      setIsCookieAccepted(true);
    }
  }, []);

  const loadView = () => {
    //avoid to preload the login component when its not necessary
    if (isLoggedIn !== null) {
      return isLoggedIn ? <Dashboard /> : <Login />;
    }
  };

  return (
    <>
      {!isCookieAccepted && <CookieConsent />}
      <div className={classes.info}>
        <HelpButton helperItems={helperItems} header={helperHeader} />
      </div>
      <Grid
        className={classes.root}
        container
        direction={'row'}
        wrap={'nowrap'}
        justify={'space-evenly'}
        alignItems={'center'}>
        <Grid
          className={classes.menuContainer}
          container
          item
          direction={'column'}
          wrap={'nowrap'}
          spacing={2}
          justify={'space-evenly'}
          alignItems={'stretch'}>
          <Grid item>
            <img className={classes.logo} src={logo} alt='BTJ logo' />
          </Grid>
          {loadView()}
        </Grid>
      </Grid>
    </>
  );
}

export default Home;

import React from 'react';
import { TableCell, Typography } from '@material-ui/core';
import FormValues from '../types/FormValues.type';
import ShadedTableRow from './ShadedTableRow';

type Props = {
  languages: FormValues['languages'];
  parallelText: FormValues['parallelText'];
};

function ResultsTableLanguages({ parallelText, languages = [] }: Props) {
  return (
    <>
      <ShadedTableRow>
        <TableCell>
          <Typography>Parallelltext</Typography>
        </TableCell>
        <TableCell>
          {parallelText ? (
            <Typography>Ja</Typography>
          ) : (
            <Typography>Nej</Typography>
          )}
        </TableCell>
      </ShadedTableRow>
      <ShadedTableRow>
        <TableCell>
          <Typography>Bokens språk</Typography>
        </TableCell>
        <TableCell component='td' scope='row' align={'left'}>
          {languages.map((language, index) => (
            <li key={index}> {language.name}</li>
          ))}
        </TableCell>
      </ShadedTableRow>
    </>
  );
}

export default ResultsTableLanguages;

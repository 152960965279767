import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useAuth } from 'oidc-react';
import { removeSessionStatus } from '../services/Auth.service';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  open: boolean;
};

function ExpiredSession({ open }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const auth = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsOpen(open);
  }, []);

  useEffect(() => {
    if (pathname === '/') {
      setIsOpen(false);
    }
  }, [pathname]);

  const backToLoginPage = async () => {
    auth.userManager.clearStaleState();
    removeSessionStatus();
    await auth.userManager.removeUser();
    setIsOpen(false);
    navigate('/');
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle>{'Sessionen har löpt ut'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <Typography variant={'subtitle2'} component={'span'}>
            Du har blivit utloggad, och måste logga in igen för att kunna
            fortsätta använda appen.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={backToLoginPage} variant={'contained'}>
          Stäng
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExpiredSession;

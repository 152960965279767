import React from 'react';
import { Link } from 'react-router-dom';

import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Theme,
  Typography,
} from '@material-ui/core';

import LibraryInfoValues from '../../types/LibraryInfoValues.type';
import ShadedTableRow from '../../components/ShadedTableRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '1rem  0 !important',
    },
    table: {
      borderStyle: 'none',
    },
    tableCell: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: 'none',
    },
    span: {
      flex: 1,
      textAlign: 'right',
      padding: '0.15rem',
      lineHeight: '1.25rem',
    },
    description: {
      lineHeight: '1.25rem',
    },
  })
);

function ExcessInfo({
  excesses,
  showQuota,
}: {
  excesses: Array<LibraryInfoValues>;
  showQuota: boolean;
}) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root} item>
        <Typography component={'h2'} variant={'h3'} paragraph>
          {showQuota
            ? 'Slutförda beställningar utöver fri katalogiseringskvot'
            : 'Summering av slutförda beställningar'}
        </Typography>
        <TableContainer className={classes.table} component={Paper}>
          <Table>
            <TableBody>
              {excesses.map(({ description, amount, unit }, index) => {
                return (
                  <ShadedTableRow key={index}>
                    <TableCell className={classes.tableCell}>
                      <Grid style={{ flex: 2 }} item>
                        <Typography
                          className={classes.description}
                          component={'span'}
                          variant={'subtitle1'}>
                          {description}:
                        </Typography>
                      </Grid>
                      <Grid container style={{ flex: 1 }} item>
                        <Typography
                          className={classes.span}
                          component={'span'}
                          variant={'subtitle2'}>
                          {amount}&nbsp;{unit}
                        </Typography>
                      </Grid>
                    </TableCell>
                  </ShadedTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item>
        <Typography component={'span'} variant={'subtitle1'} paragraph>
          Ännu ej slutförda beställningar hittar du under&nbsp;
          <Link to='/order/history'>Mina beställningar</Link>
        </Typography>
      </Grid>
      <Grid item>
        <hr />
      </Grid>
    </>
  );
}

export default ExcessInfo;

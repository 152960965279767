import * as yup from 'yup';

const BookOrderValidationSchema = yup.object().shape({
  // ISBN PAGE
  isbn: yup.string().trim().uppercase().nullable().notRequired(),

  // LANGUAGE PAGE
  unknownLanguage: yup
    .mixed()
    .oneOf([true, false], 'Du måste välja något alternativ.')
    .required('Du måste välja något alternativ.'),

  // languages: yup.array().of(yup.object()).required('NO LANGUAGES'),

  parallelText: yup
    .mixed()
    .oneOf([true, false], 'Du måste ange om boken har parallell-text.')
    .required('Du måste ange om boken har parallell-text.'),

  // CONTENT PAGE
  tableOfContents: yup
    .mixed()
    .oneOf([true, false], 'Du måste ange om boken har innehållsförteckning.')
    .required('Du måste ange om boken har innehållsförteckning.'),
  hasIllustrations: yup
    .mixed()
    .oneOf([true, false], 'Du måste ange om boken har illustrationer.')
    .required('Du måste ange om boken har illustrationer.'),
  spiralBinding: yup
    .mixed()
    .oneOf([true, false], 'Du måste ange om boken är spiralbundet.')
    .required('Du måste ange om boken är spiralbundet.'),
  numberOfPages: yup
    .string()
    .min(1, 'Du måste ange sidantal.')
    .max(20, 'Max 20 tecken.')
    .nullable(),

  // PHOTO PAGE
  images: yup.array().nullable().required('Du måste bifoga bilder på objektet'),

  // APPENDIXES PAGE
  appendixes: yup
    .array()
    .nullable()
    .required('Du måste bifoga bilder på objektet'),
  hasAppendixes: yup
    .mixed()
    .oneOf([true, false], 'Du måste ange om boken har bilagor e.d.')
    .required('Du måste ange om boken har bilagor e.d.'),

  illustrations: yup
    .array()
    .nullable()
    .required('Du måste bifoga bilder på objektet'),

  // RESULTS PAGE
  notes: yup
    .string()
    .trim()
    .max(2000, 'Kommentaren får inte vara längre än 2000 tecken.')
    .nullable(true)
    .notRequired(),

  numberOfLabels: yup
    .number()
    .transform((value) => (isNaN(value) ? 0 : value))
    .required('Du måste ange antal etiketter.')
    .integer('Antal etiketter måste vara ett heltal')
    .min(1, 'Du måste ange antal etiketter.')
    .max(100, 'Du kan som mest beställa 100 etiketter per objekt.')
    .nullable()
    .notRequired(),
});

export default BookOrderValidationSchema;

import React from 'react';
//style
import lightTheme from './theme/btj.theme.light';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container, createStyles, makeStyles, Theme } from '@material-ui/core';
import ReactGA from 'react-ga4';
import config from './helpers/makeConfig';

//components
import AuthenticationProvider from './auth/AuthenticationProvider';
import BtjState from './context/BtjState';
import AppRoutes from './Routes';
import IWindowSize from './types/WindowSize';
import useWindowSize from './helpers/useWindowSize';

const useStyles = makeStyles<Theme, IWindowSize>((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: (props) => `${props.height}px`,
      overflowX: 'hidden',
      overflowY: 'auto',
      position: 'relative',
    },
  })
);

function initializeReactGA() {
  ReactGA.initialize([
    {
      trackingId: config.ga4TrackingId || 'G-2ZZ7QEGRLV', // Default to development if no tracking id is defined in environment
      gaOptions: {custom_map: {
        dimension1: 'customerName',
        dimension2: 'appenService',
        dimension3: 'actionDetail',
        dimension4: 'success',
        dimension5: 'title',
        dimension6: 'isbn',
        dimension7: 'followLink'
      }},
    }
  ]);
  ReactGA.send("pageview");
}

function App() {
  const windowSize = useWindowSize();
  const classes = useStyles(windowSize);
  initializeReactGA();

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline>
        <AuthenticationProvider>
          <BtjState>
            <Container
              id='app'
              className={classes.root}
              maxWidth={false}
              disableGutters>
              <AppRoutes />
            </Container>
          </BtjState>
        </AuthenticationProvider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import './fonts.css';

import App from './App';
import config from './helpers/makeConfig';
import { BrowserRouter } from 'react-router-dom';

document.title = `${
  config.environment === 'Production' ? '' : `(${config.environment})`
} BTJ-appen`;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

if (config.environment !== 'LOCAL') {
  navigator.serviceWorker.register('/service-worker.js');
}

import PriceInfo from '../../types/PriceInfo.type';

async function getPriceInfoFromApi(): Promise<PriceInfo[] | undefined> {
  const res = await fetch('api/v1/titlepage/catalogingorder/priceinfo', {
    method: 'GET',
  });

  if (res.status === 200) {
    return res.json();
  }
}

export default getPriceInfoFromApi;

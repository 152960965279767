import FormPage from '../../../components/FormPage';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import RadioButtons from '../../../components/RadioButtons';
import LanguageSelect from '../../../components/LanguageSelect';
import FormPageProps from '../../../types/FormPageProps.type';
import { Language } from '../../../types/Language.type';
import parallelltext from '../../../assets/images/parallelltext.jpg';
import cachedLanguages from '../../../assets/languages.json';
import getLanguagesFromApi from '../../../services/Language.service';
const initialValues = {
  languages: [],
  parallelText: false,
};

function LanguageForm({
  values,
  touched,
  errors,
  onChange,
  onBlur,
  setFieldError,
  setFieldValue,
  setActiveFields,
  textContent,
}: FormPageProps) {
  const [languages, setLanguages] = useState<Language[]>(cachedLanguages);
  const {
    pageHeader,
    pageInstruction,
    helperHeader,
    helperItems,
  } = textContent.languageForm;
  helperItems[1].imageURL = parallelltext;
  useEffect(() => {
    const abortController = new AbortController();
    setActiveFields(['languages', 'parallelText', 'unknownLanguage']);
    getLanguagesFromApi(abortController.signal)
      .then((newLanguages) => newLanguages && setLanguages(newLanguages))
      .catch((err) => {
        console.log(err);
      });

    return () => {
      abortController.abort();
    };
  }, [setActiveFields]);

  const handleLanguageChange = (e: React.ChangeEvent<{}>, value: {}[]) => {
    setFieldValue(
      'languages',
      value !== null ? value : initialValues.languages
    );
  };

  function handleRadioChange(event: ChangeEvent<HTMLInputElement>) {
    setFieldError(event.target.name, undefined);
    setFieldValue(event.target.name, event.target.value === 'true');
  }

  function handleUnknownLanguages(event: ChangeEvent<HTMLInputElement>) {
    const languagesAreUnknown = event.target.value === 'true';
    setFieldValue('unknownLanguage', languagesAreUnknown);
    if (languagesAreUnknown) {
      setFieldValue('languages', []);
    }
    handleRadioChange(event);
  }

  return (
    <FormPage
      header={pageHeader}
      instruction={pageInstruction}
      helperHeader={helperHeader}
      helperItems={helperItems}>
      <Grid item xs={12}>
        <RadioButtons
          value={values.unknownLanguage}
          error={errors.unknownLanguage}
          name={'unknownLanguage'}
          onBlur={onBlur}
          onChange={handleUnknownLanguages}
          touched={touched.unknownLanguage}
          alternatives={[
            {
              label: 'Boken är skriven på ett eller fler språk',
              value: false,
            },
            {
              label: 'Det finns språk jag inte kan identifiera',
              value: true,
            },
          ]}
          text={'Språkinformation'}
        />
      </Grid>
      <Grid item xs={12}>
        <LanguageSelect
          languages={languages}
          value={values.languages}
          name={'languages'}
          handleBlur={onBlur}
          disabled={values.unknownLanguage === null || values.unknownLanguage}
          handleChange={handleLanguageChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.parallelText}
              onChange={onChange}
              name={'parallelText'}
              disabled={!!values.unknownLanguage}
            />
          }
          label={
            <Typography variant={'h3'} component={'span'}>
              Boken har parallelltext
            </Typography>
          }
        />
      </Grid>
    </FormPage>
  );
}

export default LanguageForm;
